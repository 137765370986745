import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiAccountCowboyHatOutline, mdiTools, mdiStoreSearch, mdiCog } from '@mdi/js';
import './SideNavbar.css';
import { Settings } from '@mui/icons-material';

const SideNavbar = ({ setLeads, setHome, setClient, setSettings, leads, home, clients, settings}) => {
  useEffect(() => {
    const links = document.querySelectorAll('.side-navbar a');
    links.forEach(link => link.classList.remove('active'));
    
    if (home) {
      document.querySelector('.home-link').classList.add('active');
    } else if (clients) {
      document.querySelector('.clients-link').classList.add('active');
    } else if (leads) {
      document.querySelector('.leads-link').classList.add('active');
    } else if (settings) {
      document.querySelector('.settings-link').classList.add('active');
    }
  }, [home, clients, leads]);

  const handleOnLeadsClick = () => {
    setHome(false);
    setClient(false);
    setLeads(true);
  }
  const handleOnHomeclick = () => {
    setHome(true);
    setClient(false);
    setLeads(false);
  }

  const handleOnClientClick = () => {
    setHome(false);
    setClient(true);
    setLeads(false);
  }

  return (
    <div className="side-navbar">
      <ul>
        <li>
         <NavLink onClick={handleOnHomeclick} className='home-link'>
            <Icon path={mdiAccountCowboyHatOutline} size={0.9} />
            <span style={{fontSize: '0.9rem'}}>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleOnClientClick} className='clients-link'>
            <Icon path={mdiTools} size={0.9} />
            <span style={{fontSize: '0.9rem'}}>Accounts</span>
          </NavLink>
        </li>
        <li>
          <NavLink onClick={handleOnLeadsClick} className='leads-link'>
            <Icon path={mdiStoreSearch} size={0.9} />
            <span style={{fontSize: '0.9rem'}}>Leads</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/CRM/settings" activeClassName="active">
            <Icon path={mdiCog} size={0.9} />
            <span style={{fontSize: '0.9rem'}}>Settings</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default SideNavbar;

