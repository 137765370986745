import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { Card } from "react-bootstrap";
import Button from "@mui/material/Button";
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import DoDisturbSharpIcon from '@mui/icons-material/DoDisturbSharp';
import Alert from '@mui/material/Alert';
import { useAuth0 } from "@auth0/auth0-react";
import { property } from "lodash";

function Contact({ contact, accessToken }) {
  const [showAlert, setShowAlert] = useState(false);

  // Check if prop_context array has items and if so, access the first item
  const propertyContext = contact.prop_context.length > 0 ? contact.prop_context[0] : null;
  const propertyName = propertyContext ? propertyContext.job.property_name : 'Not available';
  const propertyjobtitle = propertyContext ? propertyContext.job.title : 'Not available';
  const propertyjobtype = propertyContext ? propertyContext.job.job_type : 'Not available';

  async function InsertContacts(accessToken, newContact) {
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/insert_contact`, newContact, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

async function handleNewRow() {
  const newContact = {
    job_id: contact.new_contact.job_id,
    name: contact.new_contact.name,
    job_title: contact.new_contact.job_title,
    company: contact.new_contact.company,
    email: contact.new_contact.email,
    phone: contact.new_contact.phone,
    socials: '',
  }
  const newId = await InsertContacts(accessToken, newContact);
  if (newId) {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 10000); // Hide after 10 seconds
  }
}

  return (
      <div>
        <h1>Property: {propertyName}</h1>
        <p>Job Title: {propertyjobtitle}</p>
        <p>Job Type: {propertyjobtype}</p>
        <p>Name: {contact.new_contact.name}</p>
        <p>Job title: {contact.new_contact.job_title}</p>
        <p>Company: {contact.new_contact.company}</p>
        <p>Email: {contact.new_contact.email}</p>
        <p>Phone: {contact.new_contact.phone}</p>
        <h4>is this correct?</h4>
        <div>
          <Button onClick={handleNewRow} variant="contained" startIcon={<TaskAltSharpIcon/>}>
            yes
          </Button>
          <Button variant="text" startIcon={<DoDisturbSharpIcon/>}>
            no
          </Button>
          {showAlert && <Alert severity="success" style={{marginTop: '1rem'}}>Successfully added contact!</Alert>}
        </div>
      </div>
  );
}

const AiAssistant = ({API_URL, accessToken}) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuth0();

  const messagesEndRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (chatOpen && !event.target.closest(".chat-card")) {
  //       setChatOpen(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //   document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [chatOpen]);


  const sendMessage = async () => {
    setLoading(true);
    setMessages([
      ...messages,
      { user: "User", content: inputMessage },
      { user: "AI", content: "...", temporary: true },
    ]);
    try {
      console.log("USER", user.nickname);
      const response = await axios.post(API_URL, { question: inputMessage , user: user.nickname}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const answer = response.data;
      let parsedAnswer;
      if (typeof answer === "string") {
        parsedAnswer = answer;
      } else if (typeof answer === "object" && answer !== null) {
        if (answer.new_contact) {
          console.log("parsedAnswer", parsedAnswer);
          parsedAnswer = <Contact contact={answer} accessToken={accessToken}/>
        } else {
          console.log("parsedAnswer", parsedAnswer);
          parsedAnswer = answer;
        }
      }
      setMessages((prevMessages) => {
        const newMessages = prevMessages.slice(0, -1); 
        newMessages.push({ user: "AI", content: parsedAnswer }); 
        return newMessages;
      });
      setInputMessage("");
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  
    setLoading(false);
  };

  const handleScroll = () => {
    const element = messagesEndRef.current;
    if (element.scrollTop === 0) {
      // fetch old messages
    }
  };

  return (
    <div style={{ width: '30%', position: "fixed", bottom: "10px", left: "6rem" }}>
        <Card style={{backgroundColor: 'rgba(237, 239, 242, .75)'}} className="chat-card">
          <Card.Body
            ref={messagesEndRef}
            className="chat-card-body"
            style={{
              overflowY: "scroll",
              maxHeight: "800px",
              backgroundColor: "transparent",
            }}
            onScroll={handleScroll}
          >
            <MessageList messages={messages} />
          </Card.Body>
          <MessageInput
            value={inputMessage}
            onSendMessage={sendMessage}
            onInputChange={(value) => setInputMessage(value)}
            placeholder="Type your question and press Enter"
            loading={loading}
          />
        </Card>
    </div>
  );
};

export default AiAssistant; 
