import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatIcon from '@mui/icons-material/Chat';
import InsightsIcon from '@mui/icons-material/Insights';
import { Icon, IconSize } from "@blueprintjs/core";
import EditIcon from '@mui/icons-material/Edit';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AiAssistant from './AIagent';
import { set } from 'lodash';


export default function OpenIconSpeedDial(accessToken) {
  const [mailAI, setMailAI] = React.useState(false);
  const [chatAI, setChatAI] = React.useState(false);
  const [insightsAI, setInsightsAI] = React.useState(false);

  const aiAssistantRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (aiAssistantRef.current && !aiAssistantRef.current.contains(event.target)) {
        setMailAI(false);  // If clicked outside of AiAssistant, close it
        setChatAI(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [aiAssistantRef]);

  const mail_api = `${process.env.REACT_APP_API_URL}/get_email`;
  const chat_api = `${process.env.REACT_APP_API_URL}/agent_main`;

  function handleMailAIOpen() {
    setMailAI(true);
  }
  function handleChatAIOpen() {
    setChatAI(true);
  }

  const actions = [
    { icon: <InsightsIcon />, name: 'AI analytics' },
    { icon: <MailOutlineIcon onClick={handleMailAIOpen}/>, name: 'AI Email Assitant' },
    { icon: <ChatIcon onClick={handleChatAIOpen}/>, name: 'AI Chat Assitant' },
  ];

  return (
    <div style={{ position: "fixed", bottom: "10px", left: "10px" }}>
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: 16, left: 16 }}  // changed right to left and absolute to fixed
        icon={<TipsAndUpdatesIcon sx={{color: 'white'}}/>}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
    {mailAI && 
    <div ref={aiAssistantRef}>
     <AiAssistant style={{marginLeft: '3rem'}} API_URL={mail_api} accessToken={accessToken.accessToken}/>
     </div>
    }
    {chatAI && 
    <div ref={aiAssistantRef}>
     <AiAssistant style={{marginLeft: '3rem'}} API_URL={chat_api} accessToken={accessToken.accessToken}/>
     </div>
    }
    </div>
  );
}
