import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import axios from "axios";
import "../App.css";
import "./dashboard.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pie from "./PieChart";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {useAuth0} from "@auth0/auth0-react";
import {QueryClient, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

const Home = ({setTaskJob}) => {
    const {getAccessTokenSilently, user} = useAuth0();
    const [accessToken, setAccessToken] = useState(null);
    useEffect(() => {
        let isMounted = true;

        const getMessage = async () => {
            const token = await getAccessTokenSilently();
            if (!isMounted) {
                return;
            }
            setAccessToken(token);
        };
        getMessage();

        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    if (user) {
        console.log("USER", user.sub);
    }

    if (!accessToken) {
        return <div>Loading...</div>;
    }
    return (
        <div
            className="app-root alliance-font"
            style={{minHeight: "100%", backgroundColor: "#EDEFF2"}}
        >
            <Container style={{marginLeft: "10%", minWidth: "100%", minHeight: "1000px"}}>
                <h1 className="crm-dashboard" style={{color: "#252A31"}}>
                    HOME
                </h1>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={2.8}>
                            <Card sx={{width: "100%", height: "100%"}}>
                                <h1
                                    style={{
                                        fontSize: "1.9rem",
                                        paddingLeft: "2rem",
                                        paddingTop: "1rem",
                                    }}
                                >
                                    Tasks
                                </h1>
                                {/* <Profile/> */}
                                <Tasks
                                    setTaskJob={setTaskJob}
                                    token={accessToken}
                                    user_id={user.sub}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Card
                                sx={{
                                    height: "100%",
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    paddingBottom: 2,
                                }}
                            >
                                <h1
                                    style={{
                                        fontSize: "1.9rem",
                                        paddingLeft: "2rem",
                                        paddingTop: "1rem",
                                    }}
                                >
                                    Revenue by Service
                                </h1>
                                <Pie style={{paddingBottom: ""}} accessToken={accessToken} type="dealRevenue"/>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Card
                                sx={{
                                    height: "100%",
                                    justifyContent: "center",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingBottom: "2rem",
                                }}
                            >
                                <h1
                                    style={{
                                        fontSize: "1.9rem",
                                        paddingLeft: "2rem",
                                        paddingTop: "1rem",
                                    }}
                                >
                                    Deals Closed
                                </h1>
                                <Pie style={{paddingBottom: ""}} accessToken={accessToken} type="dealsClosed"/>
                            </Card>
                        </Grid>
                        {/*<Grid item xs={12} md={5}>*/}
                        {/*<Card*/}
                        {/*  sx={{*/}
                        {/*    height: "90%",*/}
                        {/*    width: "100%",*/}
                        {/*    paddingBottom: "2rem",*/}
                        {/*    marginBottom: "2rem",*/}
                        {/*    paddingLeft: "1rem",*/}
                        {/*    paddingRight: "1rem",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  <h1*/}
                        {/*    style={{*/}
                        {/*      fontSize: "1.9rem",*/}
                        {/*      paddingLeft: "2rem",*/}
                        {/*      paddingTop: "1rem",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Monthly Outreach*/}
                        {/*  </h1>*/}
                        {/*  <LineChart />*/}
                        {/*</Card>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={5.5}>*/}
                        {/*  <Card*/}
                        {/*    sx={{*/}
                        {/*      minWidth: 275,*/}
                        {/*      paddingBottom: "2rem",*/}
                        {/*      marginBottom: "2rem",*/}
                        {/*      paddingLeft: "1rem",*/}
                        {/*      paddingRight: "1rem",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    <h1*/}
                        {/*      style={{*/}
                        {/*        fontSize: "1.9rem",*/}
                        {/*        paddingLeft: "1rem",*/}
                        {/*        paddingTop: "1rem",*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      Leads Generated*/}
                        {/*    </h1>*/}
                        {/*    <AreaChart />*/}
                        {/*  </Card>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};
export default Home;

const queryClient = new QueryClient();

function GetProperty({token, user_id}) {
    console.log("USER ID", user_id);
    const uid = user_id.split("|")[1];
    return useQuery({
        queryKey: ["todo_smallcard"],
        queryFn: async () => {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_URL}/dash_priority/${uid}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("TASKKKSSS", data);
            return data;
        },
    });
}

const style = {
    width: "100%",
    bgcolor: "background.paper",
};

const getColorAndBackground = (priority) => {
    switch (priority) {
        case "Low":
            return {color: "#29A634", background: "rgba(142, 177, 37, .3)"};
        case "Medium":
            return {
                color: "rgba(255,116,32,1)",
                background: "rgba(240, 183, 38, .5)",
            };
        case "High":
            return {
                color: "rgba(255, 0, 0, .9)",
                background: "rgba(255, 0, 0, .2)",
            };
        case "Critical":
            return {color: "#9D3F9D", background: "rgba(157, 63, 157, .3)"};
        default:
            return {color: "", background: ""};
    }
};

function Tasks({setTaskJob, token, user_id}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const {status, data, error} = GetProperty({token, user_id});
    const [currentTask, setCurrentTask] = useState(null);

    const queryClient = useQueryClient();

    const handleClick = (event, task) => {
        setAnchorEl(event.currentTarget);
        setCurrentTask(task);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentTask(null);
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    async function updateJob(accessToken, id, completed) {
        console.log("ACCESSTOKEN ", accessToken)
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/check_job`,
                {
                    id,
                    completed,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log(response.data);
        } catch (error) {
            console.error(`Error updating job: ${error}`);
        }
    }

    // const handleCheckboxChange = async (task) => {
    //     console.log("remove TASK by ID", task.id)
    //     // await updateJob(accessToken, task.id, !task.completed);
    // };

    const mutation = useMutation(
        async ({ id, completed }) => {
            return await axios.put(
                `${process.env.REACT_APP_API_URL}/check_job`,
                { id, completed },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        },
        {
            // On success, update the cached data
            onSuccess: (data, variables) => {
                const { id } = variables;

                // Update the query cache after task is updated
                queryClient.setQueryData(["todo_smallcard"], (oldData) => {
                    return oldData.filter((task) => task.id !== id);
                });
            },
            onError: (error) => {
                console.error(`Error updating task: ${error}`);
            },
        }
    );

    // Handle checkbox change and call mutation
    const handleCheckboxChange = async (task) => {
        console.log("Removing task by ID", task.id);

        mutation.mutate({
            id: task.id,
            completed: !task.completed,
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <List
            style={{width: "100%"}}
            sx={style}
            component="nav"
            aria-label="mailbox folders"
        >
            {data &&
                data.map((task) => (
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Checkbox
                            defaultChecked={task.completed}
                            color="success"
                            onChange={() => handleCheckboxChange(task)}
                        />
                        <ListItem
                            button
                            aria-describedby={id}
                            onClick={(event) => handleClick(event, task)}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <ListItemText
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Typography sx={{fontSize: ".75rem"}}>
                                        {task.title}
                                    </Typography>
                                    <Typography sx={{color: "#5F6B7C", fontSize: ".75rem"}}>
                                        {task.deadline}
                                    </Typography>
                                </ListItemText>
                                <Box
                                    component="span"
                                    sx={{
                                        display: "inline-flex",
                                        fontWeight: "500",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "5rem",
                                        fontSize: ".75rem",
                                        borderRadius: "40px",
                                        padding: "0.2rem 0.45rem",
                                        background: getColorAndBackground(task.priority).background,
                                        color: getColorAndBackground(task.priority).color,
                                        marginRight: ".5rem",
                                    }}
                                >
                                    {task.priority}
                                </Box>
                            </Box>
                            <Divider/>
                        </ListItem>
                    </div>
                ))}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "right",
                    horizontal: "right",
                }}
            >
                {currentTask && (
                    <div style={{height: "20rem", width: "20rem"}}>
                        <IconButton onClick={handleMenuClick}>
                            <MoreVertIcon style={{marginTop: "0.4rem"}}/>
                        </IconButton>
                        <div>
                            <h3 style={{paddingLeft: "2rem"}}>{currentTask.title}</h3>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Typography
                                sx={{marginLeft: "2rem", fontSize: "15px", color: "#383E47"}}
                            >
                                {currentTask.deadline}
                            </Typography>
                            <Box
                                component="span"
                                sx={{
                                    display: "inline-flex",
                                    fontWeight: "500",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "55px",
                                    fontSize: "11.2px",
                                    borderRadius: "40px",
                                    padding: "3.2px 9.6px",
                                    background: getColorAndBackground(currentTask.priority)
                                        .background,
                                    color: getColorAndBackground(currentTask.priority).color,
                                    marginRight: "8px",
                                    marginLeft: "8px",
                                }}
                            >
                                {currentTask.priority}
                            </Box>
                        </div>
                        <Typography sx={{marginLeft: "2rem", marginTop: "0.5rem"}}>
                            {currentTask.description}
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyItems: "center",
                                paddingLeft: "2rem",
                                paddingTop: "1rem",
                            }}
                        >
                            <button
                                onClick={() => setTaskJob(currentTask.large_card_id)}
                                style={{
                                    color: "#60a5fa",
                                    borderStyle: "solid",
                                    borderColor: "#60a5fa",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                }}
                            >
                                View Project
                            </button>
                        </div>
                    </div>
                )}
            </Popover>
        </List>
    );
}

function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Open Popover
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "right",
                    horizontal: "right",
                }}
            >
                <Typography sx={{p: 2}}>The content of the Popover.</Typography>
            </Popover>
        </div>
    );
}
