import React, { useState }from 'react';
import { 
    GridActionsCellItem, 
    useGridApiRef, 
    DataGridPro, 
    GridCellEditStopReasons, 
    GridToolbarContainer, 
    GridToolbarExport
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';
import Button from '@mui/material/Button';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { set } from 'lodash';
import axios from "axios";
import {
    useQuery,
  } from "@tanstack/react-query";


export default function ContactGrid({accessToken, jobId, reset}) {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiRef = useGridApiRef();

  function CustomToolbar() {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end'}}>
        <Button onClick={handleNewRow}>
          Add Contact + 
        </Button>
      <GridToolbarContainer>
       <GridToolbarExport />
      </GridToolbarContainer>
    </div>
    );
  }

  React.useEffect(() => {
    const fetchContacts = async () => {
      const contacts = await GetContacts(accessToken, jobId);
      setRows(contacts);
    };
    
    fetchContacts();
  }, [accessToken, jobId]);

  async function GetContacts(accessToken, jobId) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_contacts/${jobId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    return response.data;
  }

  async function InsertContacts(accessToken, newContact) {
    let response = await axios.put(`${process.env.REACT_APP_API_URL}/insert_contact`, newContact, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  async function DeleteRow(accessToken, id){
    let response = await axios.delete(`${process.env.REACT_APP_API_URL}/del_contact/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log('DELETO', response);
    return response.data;
}
  
  const processRowUpdate = React.useCallback(
      async (newRow) => {
        console.log("NEWROW", newRow);
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/edit_contact`, newRow, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('INSERTO', response);
      return response.data;
    },
  );
  async function handleNewRow() {
    const newContact = {
      id: 1000111,
      job_id: jobId,
      name: '',
      job_title: '',
      company: '',
      email: '',
      phone: '',
      socials: '',
    }
    setRows(prevRows => [...prevRows, newContact]);
    const newId = await InsertContacts(accessToken, newContact);
    setRows(prevRows => prevRows.map(row => row.id === newContact.id ? {...row, id: newId} : row));
  }


 const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    DeleteRow(accessToken, id);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 100,
      editable: true,
    },
    {
      field: 'job_title',
      headerName: 'Job',
      width: 140,
      editable: true,
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 140,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 160,
      editable: true,
    },
     {
      field: 'phone',
      headerName: 'Phone',
      width: 100,
      editable: true,
    }, 
    {
      field: 'socials',
      headerName: 'Socials',
      width: 100,
      editable: true,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => {
          return (
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
            />
          );
        },
      }
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log("ERROR",error.message);
  }, []);

  if(rows) {
  return (
    <div style={{ height: '65vh', width: '100%' }}>
    <DataGridPro
    apiRef={apiRef}
    editMode="row"
    rows={rows}
    getRowId={(row) => row.id}
    columns={columns}
    onCellEditCommit={(params) => {
        const lastColumnField = columns[columns.length - 1].field;
        if (params.field === lastColumnField) {
        apiRef.current.stopRowEditMode({
            id: params.id,
        });
        }
    }}
    processRowUpdate={processRowUpdate}
    onProcessRowUpdateError={handleProcessRowUpdateError}
    slots={{
        toolbar: CustomToolbar,
    }}
    />
    </div>
  );
 }
}
