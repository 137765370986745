import React, {useEffect, useState} from "react";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {Row, Col} from "react-bootstrap";
import {Button, Grid, Box, Tab, Tabs, AppBar} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TabContext from "@mui/lab/TabContext";
import AddPropertyModal from "./Property/AddPropertyModal";
import Search from "./Search";
import OpenIconSpeedDial from "./AI/aiFast";
import "../App.css";
import ProjectManager from "./ProjectManager/ProjectManager";
import MainGrid from "./ClientGrid/clientgrid";
import PropertyDashboard from "./Property/PropertyDashboard";
import {LicenseInfo} from "@mui/x-license-pro";
import TabPanel from "@mui/lab/TabPanel";
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const queryClient = new QueryClient();

function Clients({user, setTaskJob, taskSetJob, token}) {
    const queryClient = useQueryClient();
    const [selectedProperty] = useState(null);
    const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
    const [value, setValue] = useState("1");
    const [propertyID, setPropertyID] = useState(null);
    const [, setIsModelOpen] = useState(false);
    const [accessToken] = useState(token);
    const [search, setSearch] = useState(null);

    let query = {};

    useEffect(() => {
        if (taskSetJob) {
            setValue("2");
        }
    }, []);

    console.log(value);
    if (!search && value === "1") {
        const uuid = user.sub.split("|")[1];
        console.log("UUID", uuid);
        query = {
            queryKey: ["properties"],
            queryFn: async () => {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_URL}/all_clients/${uuid}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                return data;
            },
        };
    }

    if (search && value === "1") {
        query = {
            queryKey: ["searchPResults", search],
            queryFn: async () => {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_URL}/search_q_client/${search}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                return data;
            },
        };
    }

    const {status, data, error, refetch} = useQuery(query);

    const handleTabChange = (event, newValue) => {
        console.log("NEWVALUE", newValue);
        setValue(newValue);
    };

    const handleOnClose = () => {
        setPropertyID(null);
        setIsModelOpen(false);
    };
    const handlePropertyClick = (propertyID) => {
        setPropertyID(propertyID);
    };

    const handleAddPropertyModalClose = () => {
        refetch();
        setShowAddPropertyModal(false);
    };

    const handleAddPropertyModalOpen = () => {
        setShowAddPropertyModal(true);
    };

    function CustomToolbar() {
        return (
            <div style={{display: "flex", alignItems: "flex-end"}}>
                <Button
                    variant="outlined"
                    label="Add Property +"
                    onClick={handleAddPropertyModalOpen}
                    style={{
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        height: "2.3rem",
                        width: "15%",
                        fontSize: "0.8rem",
                    }}
                >
                    Add Property +
                </Button>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarDensitySelector/>
                    <GridToolbarExport/>
                </GridToolbarContainer>
            </div>
        );
    }

    return (
        <div
            className="app-root alliance-font"
            style={{width: "100%", height: "100vh", backgroundColor: "#EDEFF2"}}
        >
            <TabContext value={value}>
                <div style={{backgroundColor: "#EDEFF2", width: "100%"}}>
                    <div
                        style={{
                            backgroundColor: "#EDEFF2",
                            width: "82%",
                            marginLeft: "10%",
                        }}
                    >
                        <h1 className="crm-dashboard" style={{color: "#252A31"}}>
                            ACCOUNTS
                        </h1>
                        <Search setSearch={setSearch}/>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                        ></Box>
                        <AddPropertyModal
                            showAddPropertyModal={showAddPropertyModal}
                            handleAddPropertyModalClose={handleAddPropertyModalClose}
                            accessToken={accessToken}
                        />
                    </div>
                    <div style={{marginLeft: "10%", width: "82%"}}>
                        <Row>
                            <Col md={selectedProperty ? 14 : 12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <div>
                                            <Bar value={value} handleTabChange={handleTabChange}/>
                                            <TabPanel sx={{padding: 0}} value="1">
                                                {!data ? (
                                                    <div style={{height: "100vh"}}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                height: "50vh",
                                                            }}
                                                        >
                                                            <CircularProgress/>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <MainGrid
                                                        uuid={user.sub}
                                                        data={data}
                                                        handlePropertyClick={handlePropertyClick}
                                                        accessToken={accessToken}
                                                        CustomToolbar={CustomToolbar}
                                                    />
                                                )}
                                                {propertyID && (
                                                    <PropertyDashboard
                                                        uuid={user.sub.split("|")[1]}
                                                        iD={propertyID}
                                                        onClose={handleOnClose}
                                                        accessToken={accessToken}
                                                    />
                                                )}
                                            </TabPanel>
                                            <TabPanel sx={{padding: 0}} value="2">
                                                <ProjectManager
                                                    job={taskSetJob}
                                                    value={value}
                                                    token={accessToken}
                                                    jobSearch={search}
                                                    user={user}
                                                    setTaskJob={setTaskJob}
                                                />
                                            </TabPanel>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Col>
                        </Row>
                    </div>
                </div>
                <OpenIconSpeedDial accessToken={accessToken}/>
            </TabContext>
        </div>
    );
}

export default Clients;

const Bar = ({value, handleTabChange}) => {
    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{flexGrow: 1, boxShadow: "none", padding: 0}}
        >
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
            >
                <Tab
                    label="Clients"
                    value="1"
                    sx={{height: "4vh", width: "12%", fontSize: "0.75rem"}}
                />
                <Tab
                    label="Project Manager"
                    value="2"
                    sx={{width: "15%", fontSize: "0.75rem"}}
                />
            </Tabs>
        </AppBar>
    );
};
