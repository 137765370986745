import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import './messagelist.css';

const MessageList = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

const parseTableContent = (message) => {
  const rows = message.split('\n');
  const headerRow = rows[0].split('|').slice(1, -1);
  const dataRows = rows
    .slice(2)
    .map((row) => row.split('|').slice(1, -1))
    .filter((row) => !row.every((cell) => /^-+$/.test(cell.trim())));
  return { headerRow, dataRows };
};

const formatMessage = (message) => {
  if (typeof message === 'string') {
  if (message.includes('|')) {
    const { headerRow, dataRows } = parseTableContent(message);
    return (
      <TableContainer>
        <Table style={{color: '#transparent'}}>
          <TableHead>
            <TableRow>
              {headerRow.map((header, index) => (
                <TableCell key={index}             
		            sx={{
                borderBottom: "1px solid #5F6B7C",
                }}>{header.trim()}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                 <TableCell 
                sx={{borderBottom: rowIndex !== dataRows.length - 1 ? "1px solid #5F6B7C" : "none"}}
                key={cellIndex}>{cell.trim()}
                </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {	    
   return message.split('\n').map((line, index) => (
	  <React.Fragment key={index}>
	  {line}
	    <br />
	     </React.Fragment>
	    ));
      }
	  } else {
      return message;
    }
	};

  return (
    <div className='message-list'>
      {messages.map((message, index) => {
        if (message.user === 'User') {
          return (
            <div key={index} className='user-message'>
              {formatMessage(message.content)}
            </div>
          );
        } else {
          return (
            <Card
              style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
              key={index}
              className={`message-card ${
                message.user === 'AI' ? 'ai-card' : 'user-card'
              }`}
            >
              <Card.Body>
                <Card.Title>{message.user}</Card.Title>
                <Card.Text>
                  {message.temporary ? (
                    <span className='loading-symbol'>...</span>
                  ) : (
                    formatMessage(message.content)
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          );
        }
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;

