import React from "react";
import Input from "@mui/material/Input";
import { Form, Button } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

const MessageInput = ({
  value,
  onSendMessage,
  onInputChange,
  placeholder,
  loading,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSendMessage();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSendMessage();
    }
  };

  return (
    <form style={{ backgroundColor: "#F6F7F9" }} onSubmit={handleSubmit} className="message-input-form">
      <Box
        sx={{ backgroundColor: "#F6F7F9" }}
        display="flex"
        justifyContent="center"
      >
        <Form.Control
          as="textarea"
          style={{
            paddingLeft: "1rem",
            paddingBottom: ".4rem",
            paddingTop: ".4rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            width: "90%",
            backgroundColor: "rgba(211, 211, 211, 0.5)",
            boxShadow: "none",
            resize: "none",
            overflow: "hidden",
          }}
          rows={2}
          value={value}
          onChange={(event) => onInputChange(event.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          className="message-input"
          readOnly={loading}
        />
        <Button
          type="submit"
          disabled={loading}
          variant="link"
          style={{
            position: "absolute",
            right: "1rem",
            bottom: "2.5rem",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <SendIcon style={{ marginRight : '1rem'}}/>
        </Button>
      </Box>
    </form>
  );
};

export default MessageInput;

