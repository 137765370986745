import React, {useState} from 'react';
import {Button, Nav} from 'react-bootstrap';
import {TextField} from '@mui/material';
import JobTypeOption from './JobTypeOption';
import './AddPastJob.css'
import axios from 'axios';

const AddPastJob = ({uuid, token, clientId, onJobAdded, propertyName}) => {
    const [JobType, setJobType] = useState('');
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [revenue, setRevenue] = useState('');
    const [status, setStatus] = useState('Not Started');
    const [net_zero_equipment, setNetZeroEquipment] = useState('');
    const [trelloLink, setTrelloLink] = useState('');

    const [freeBulbs, setFreeBulbs] = useState('');
    const [bulbDollars, setBulbDollars] = useState('');
    const [bulbKwReduced, setBulbKwReduced] = useState('');

    const [unitsPtac, setPtacUnits] = useState('');
    const [ptacDollars, setPtacDollars] = useState('');
    const [ptacKwReduced, setPtacKwReduced] = useState('');

    const [freeTherms, setFreeTherms] = useState('');
    const [thermDollars, setThermDollars] = useState('');
    const [thermKwReduced, setThermKwReduced] = useState('');

    const [rebates, setRebates] = useState('');

    const [thermSaleUnits, setThermSaleUnits] = useState('');
    const [thermSaleRebates, setThermSaleRebates] = useState('');
    const [kwReducedThermSale, setKwReducedThermSale] = useState('');

    const [generalRebateProvided, setGeneralRebateProvided] = useState('');
    const [generalSavings, setGeneralSavings] = useState('');

    console.log("uuid", uuid);
    console.log("TOKEN", token);
    console.log("CLIENTID", clientId);
    console.log("PROPERTYNAME", propertyName);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let jobtype;
        switch (JobType) {
            case 'NetZeroLighting':
                jobtype = {
                    NetZeroLighting: {
                        id: parseInt(clientId),
                        job_id: 0,
                        n_free_bulbs: parseInt(freeBulbs),
                        free_bulb_dollars: parseInt(bulbDollars),
                        kw_reduced: parseInt(bulbKwReduced),
                    }
                };
                break;
            case 'NetZeroPTACcleaning':
                jobtype = {
                    NetZeroPTACcleaning: {
                        id: parseInt(clientId),
                        job_id: 0,
                        n_units_cleaned: parseInt(unitsPtac),
                        free_cleaning_dollars: parseInt(ptacDollars),
                        kw_reduced: parseInt(ptacKwReduced),
                    }
                };
                break;
            case 'NetZeroThermostats':
                jobtype = {
                    NetZeroThermostats: {
                        id: clientId,
                        job_id: 0,
                        n_free_therm: parseInt(freeTherms),
                        free_therm_dollars: parseInt(thermDollars),
                        kw_reduced: parseInt(kwReducedThermSale),
                    }
                };
                break;
            case 'NewBuildIncentives':
                jobtype = {
                    NewBuildIncentives: {
                        id: clientId,
                        job_id: 0,
                        rebate_provided: parseInt(rebates),
                    }
                };
                break;
            case 'ThermostatSale':
                jobtype = {
                    ThermostatSale: {
                        id: clientId,
                        job_id: 0,
                        n_therm: parseInt(thermSaleUnits),
                        rebate_provided: parseInt(thermSaleRebates),
                        kw_reduced: parseInt(kwReducedThermSale),
                    }
                };
                break;
            case 'General':
                jobtype = {
                    General: {
                        id: clientId,
                        job_id: 0,
                        rebate_provided: parseInt(thermSaleRebates),
                        savings: parseInt(kwReducedThermSale),
                    }
                };
                break;
        }

        console.log("TOKEN", token);

        const formattedStartDate = formatDate(start_date);
        const formattedEndDate = formatDate(end_date);
        const newJob = {
            job: {
                property_name: propertyName,
                client_id: parseInt(clientId),
                title: title,
                description: description,
                status: status,
                job_type: JobType,
                start_date: formattedStartDate ? formattedStartDate : null,
                end_date: formattedEndDate ? formattedEndDate : null,
                revenue: parseInt(revenue),
                net_zero_equipment: net_zero_equipment,
                uuid: uuid,
                trello_link: trelloLink,
            },
            jobtype,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_job`, newJob, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("Response ID:", response);
            const addedJob = {...newJob, id: response.data};
            console.log("Added job:", addedJob);
            onJobAdded(addedJob);
            console.log("New job added:", addedJob);
            resetForm();
        } catch (error) {
            console.error("Error submitting new job:", error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return date.toISOString().slice(0, 10);
    };

    const resetForm = () => {
        setStartDate('');
        setDescription('');
    };

    const renderJobStatusNavbar = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Nav variant="pills" activeKey={status} onSelect={(selectedStatus) => setStatus(selectedStatus)}>
                    <Nav.Item>
                        <Nav.Link eventKey="Not Started">Not Started</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="In Progress">In Progress</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="Completed">Completed</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        );
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{minHeight: '70vh'}}>
            <form onSubmit={handleSubmit} style={{width: '100%', maxWidth: '80%', border: 'none'}}>
                <JobTypeOption
                    JobType={JobType}

                    freeBulbs={freeBulbs}
                    bulbDollars={bulbDollars}
                    bulbKwReduced={bulbKwReduced}

                    unitsPtac={unitsPtac}
                    ptacDollars={ptacDollars}
                    ptacKwReduced={ptacKwReduced}

                    freeTherms={freeTherms}
                    thermDollars={thermDollars}
                    thermKwReduced={thermKwReduced}

                    rebates={rebates}

                    generalRebateProvided={generalRebateProvided}
                    generalSavings={generalSavings}
                    setGeneralRebateProvided={setGeneralRebateProvided}
                    setGeneralSavings={setGeneralSavings}

                    thermSaleUnits={thermSaleUnits}
                    thermSaleRebates={thermSaleRebates}
                    kwReducedThermSale={kwReducedThermSale}

                    setJobType={setJobType}
                    setFreeBulbs={setFreeBulbs}
                    setBulbDollars={setBulbDollars}
                    setBulbKwReduced={setBulbKwReduced}

                    setPtacUnits={setPtacUnits}
                    setPtacDollars={setPtacDollars}
                    setPtacKwReduced={setPtacKwReduced}

                    setFreeTherms={setFreeTherms}
                    setThermDollars={setThermDollars}
                    setThermKwReduced={setThermKwReduced}

                    setRebates={setRebates}

                    setThermSaleUnits={setThermSaleUnits}
                    setThermSaleRebates={setThermSaleRebates}
                    setKwReducedThermSale={setKwReducedThermSale}
                />
                <TextField
                    label="Start Date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={start_date}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <TextField
                    label="End Date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={end_date}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <TextField
                    label="Revenue"
                    type="number"
                    value={revenue}
                    onChange={(e) => setRevenue(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <TextField
                    label="Net Zero Equipment"
                    type="number"
                    value={net_zero_equipment}
                    onChange={(e) => setNetZeroEquipment(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <div style={{width: '100%', marginTop: '1rem'}}>
                    {renderJobStatusNavbar()}
                </div>
                <TextField
                    label="Trello Link"
                    value={trelloLink}
                    onChange={(e) => setTrelloLink(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <TextField
                    label="Description"
                    multiline
                    rows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <Button style={{marginTop: "1rem"}}
                        className="button-add custom-add-job-btn" variant="contained" color="primary" type="submit">
                    Add Job
                </Button>
            </form>
        </div>
    );
}
export default AddPastJob;
