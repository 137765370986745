import * as React from 'react';
import Button from "@mui/material/Button";
import { utils, writeFile } from 'xlsx';
import './leaderCSS.css';
import { DataGrid } from '@mui/x-data-grid';
import { Player } from '@lottiefiles/react-lottie-player';
import CircleLoader from "../Loadin";

const columns = [
    { field: 'id', headerName: 'ID', width: 100},
    { field: 'businessName', headerName: 'Business Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'website', headerName: 'Website', width: 200 },
    { field: 'profileName', headerName: 'Profile Name', width: 150 },
    { field: 'job', headerName: 'Job', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
  ];

  export default function DataTable({ response , loading }) {
    const rows = response.reduce((acc, item, index) => {
        const { name, address, website } = item.gg_data;
        const { profiles } = item.op;
      
        profiles.forEach((profile, profileIndex) => {
          acc.push({
            id: `${index}-${profileIndex}`,
            businessName: name,
            address,
            website,
            profileName: profile.name,
            job: profile.job,
            email: profile.email,
          });
        });
      
        return acc;
      }, []);
      if (loading) {
        return (
          <div style={{display: 'flex', justifyContent: 'center', allignItems: 'center', marginTop: '20rem', marginRight: '15rem'}}>
          <CircleLoader sx={50}/>
         </div>
      ); 
        } else {
            return (
                <div style={{ height: '70vh', width: '100%', paddingRight: '10%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            style={{height: '70vh'}}
                        />
                    </div>
                    <Button variant="outlined" color="primary" onClick={() => exportToExcel(rows, columns)} style={{height: '9rem'}}>
                        Download as .XLSX
                    </Button>
                </div>
            );
        }
    }

function exportToExcel(rows, columns) {
    const data = rows.map((row) => 
      columns.map((column) => 
        row[column.field]
      )
    );
  
    data.unshift(columns.map((column) => column.headerName));
  
    const worksheet = utils.aoa_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    writeFile(workbook, "data_grid.xlsx");
  }
