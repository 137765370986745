import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import LoginButton from "./SignIn";
import CircleLoader from "./Loadin";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div style={{display: 'flex', justifyContent: 'center', allignItems: 'center', marginTop: '25rem'}}>
        <CircleLoader sx={50}/>
      </div>
    ),
  });

  return <Component />;
};