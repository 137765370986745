import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Card } from '@mui/material';
import {
  DataGridPro,

} from '@mui/x-data-grid-pro';
import axios from "axios";

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);


export default function MainGrid ({data, handlePropertyClick, accessToken, uuid, CustomToolbar}) {
    const [queryOptions, setQueryOptions] = React.useState({});
    const [rata, setRata] = useState(data);
     
    const onFilterChange = React.useCallback((filterModel) => {
      console.log("PRE", filterModel);
      console.log("UUID", uuid)
      const uid = uuid.split('|')[1]
      axios.post(`${process.env.REACT_APP_API_URL}/filter/${uid}`, filterModel, {
          headers: {
              Authorization: `Bearer ${accessToken}`,
            },
      }).then( function(response) {
        console.log("RESPONSE", response.data);
        setRata(response.data);
      })
      setQueryOptions({ filterModel: { ...filterModel } });
      console.log("POST", queryOptions);
    }, []);

    return(
        <Card variant="outlined" sx={{height: '100%', width: '100%'}}>
        <DataGridPro
            columns={[
              { field: 'property_name', headerName:'Property', width: 250 },
              { field: 'ownership' , headerName:'Ownership', flex:1 },
              { field: 'utility' , headerName:'Utility', flex:1 },
              { field: 'managing_group', headerName: 'Managing Group', flex:1},
              { field: 'account_manager', headerName: 'NAM', flex:1 },
              { field: 'gm_name' , headerName: 'GM Name', flex:1 },
              { field: 'address' , headerName: 'Address', flex:1 },
              { field: 'city' , headerName: 'City', flex: 1 },
              { field: 'state' , headerName: 'State', flex:1 },
              { field: 'zip' , headerName: 'Zip',flex:1 },
              { field: 'rooms' , headerName: 'Rooms',flex:1 },
            ]}
              rows={rata}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              getRowId={(row) => row.id}
              onRowClick={(row) => handlePropertyClick(row.id)}
              sx={{ paddingTop: '0.7rem' }}
              slots={{
              toolbar: CustomToolbar,
            }}
        /> 
    </Card>
    )
}