import React, {useState} from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import './edit.css';
import {Card} from "react-bootstrap";
import {QueryClient, useQuery,} from "@tanstack/react-query";

const queryClient = new QueryClient();

function GetClientByID(token, iD) {
    return useQuery({
        queryKey: ["client", iD],
        queryFn: async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/client_by_id/${iD}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return data;
        },
    });
}

const EditableField = ({editing, value, static_value, label, onChange, token, address}) => {
    if (editing) {
        return (
            <TextField
                type="text"
                label={label}
                value={value}
                onChange={onChange}
                style={{border: 'none', background: 'none', marginLeft: '1rem', marginTop: '1rem'}}
            />
        )
    }
    if (address) {
        return (
            <Card.Text className="info-item">
                <strong style={{fontSize: '14px'}}>{label}:</strong>
                <a href={`https://www.google.com/maps/place/${address}`} target="_blank"
                   rel="noopener noreferrer">{static_value}</a>
            </Card.Text>
        )
    }
    return (
        <Card.Text className="info-item">
            <strong style={{fontSize: '14px'}}>{label}:</strong> {static_value}
        </Card.Text>
    )
};

export default function EditableInfo({token, iD}) {
    const {status, data, error, isFetching} = GetClientByID(token, iD);
    const [editing, setEditing] = useState(false);
    const [utility, setUtility] = useState(data.utility);
    const [propertyName, setPropertyName] = useState(data.property_name);
    const [gmName, setGmName] = useState(data.gm_name);
    const [gmEmail, setGmEmail] = useState(data.gm_email);
    const [address, setAddress] = useState(data.address);
    const [phone, setPhone] = useState(data.phone);
    const [city, setCity] = useState(data.city);
    const [state, setState] = useState(data.state);
    const [zip, setZip] = useState(data.zip);
    const [rooms, setRooms] = useState(data.rooms);
    const [brandCode, setBrandCode] = useState(data.brandcode);
    const [ownership, setOwnership] = useState(data.ownership);
    const [managingGroup, setManagingGroup] = useState(data.managing_group);
    const [account_manager, setAccountManager] = useState(data.account_manager);
    const [legalEntity, setLegalEntity] = useState(data.legal_entity);

    const handleEdit = () => {
        setEditing(true);
    };

    const handleEditCancel = () => {
        setEditing(false);
    };

    function handleEditSave() {
        setEditing(false);
        axios.put(`${process.env.REACT_APP_API_URL}/edit_client`, {
                id: iD,
                utility: utility,
                property_name: propertyName,
                gm_name: gmName,
                gm_email: gmEmail,
                address: address,
                phone: phone,
                city: city,
                state: state,
                zip: zip,
                rooms: rooms,
                brandcode: brandCode,
                ownership: ownership,
                managing_group: managingGroup,
                account_manager: account_manager,
                legal_entity: legalEntity,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });
    };

    return (
        <div style={{width: '100%'}}>
            <Card.Title style={{fontSize: '30px'}}>{data.property_name}</Card.Title>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', width: '100%'}}>
                    <div className="editable-fields-grid">
                        <EditableField
                            editing={editing}
                            label="Account Manager"
                            value={account_manager}
                            onChange={(e) => setAccountManager(e.target.value)}
                            static_value={account_manager}
                        />
                        <EditableField
                            editing={editing}
                            value={gmName}
                            label="GM Name"
                            onChange={(e) => setGmName(e.target.value)}
                            static_value={gmName}
                        />
                        <EditableField
                            editing={editing}
                            value={gmEmail}
                            label="Gm Email"
                            onChange={(e) => setGmEmail(e.target.value)}
                            static_value={gmEmail}
                        />
                        <EditableField
                            editing={editing}
                            value={phone}
                            label="Phone"
                            onChange={(e) => setGmEmail(e.target.value)}
                            static_value={phone}
                        />
                        <EditableField
                            editing={editing}
                            value={city}
                            label="City"
                            onChange={(e) => setCity(e.target.value)}
                            static_value={city}
                        />
                        <EditableField
                            editing={editing}
                            value={brandCode}
                            label="Brand Code"
                            onChange={(e) => setBrandCode(e.target.value)}
                            static_value={brandCode}
                        />
                        <EditableField
                            editing={editing}
                            value={state}
                            label="State"
                            onChange={(e) => setState(e.target.value)}
                            static_value={state}
                        />
                        <EditableField
                            editing={editing}
                            value={zip}
                            label="Zip"
                            onChange={(e) => setZip(e.target.value)}
                            static_value={zip}
                        />
                        <EditableField
                            address={address.replace(" ", "+") + `+${state}`}
                            editing={editing}
                            value={address}
                            label="Address"
                            onChange={(e) => setAddress(e.target.value)}
                            static_value={address}
                        />
                        <EditableField
                            editing={editing}
                            value={rooms}
                            label="Rooms"
                            onChange={(e) => setRooms(e.target.value)}
                            static_value={rooms}
                        />
                        <EditableField
                            editing={editing}
                            value={managingGroup}
                            label="Managing Group"
                            onChange={(e) => setManagingGroup(e.target.value)}
                            static_value={managingGroup}
                        />
                        <EditableField
                            editing={editing}
                            value={ownership}
                            label="Ownership"
                            onChange={(e) => setOwnership(e.target.value)}
                            static_value={ownership}
                        />
                    </div>
                    {
                        editing ? (
                            null
                        ) : (
                            <div className="edit-button">
                                <Button
                                    style={{color: "#404854"}}
                                    onClick={handleEdit}
                                    startIcon={<EditIcon style={{color: "#404854"}}/>}
                                >
                                    Edit
                                </Button>
                            </div>
                        )
                    }

                    {
                        editing ? (
                            <div>
                                <Button
                                    onClick={handleEditSave}
                                    variant="outlined"
                                    startIcon={<SaveIcon/>}
                                    style={{marginTop: '1rem', marginLeft: '1rem'}}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={handleEditCancel}
                                    variant="outlined"
                                    startIcon={<CancelIcon/>}
                                    style={{marginTop: '1rem', marginLeft: '1rem'}}
                                >
                                    Cancel
                                </Button>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
}