import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Card, MenuItem, CardContent, Typography, IconButton, Modal, Box, TextField, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDrag } from 'react-dnd';
import Checkbox from '@mui/material/Checkbox';
import { Chip } from '@mui/material';

const SmallCard = ({ id, title, description, index, setLargeCardId, largeCardId, priority, deadline, moveSmallCard , sCardId, lCardId, position, completed, fetchLargeCards, job, accessToken}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskID] = useState(id);
  const [task, setTask] = useState(completed);
  const [staticTitle, setStaticTitle] = useState(title);
  const [staticDescription, setStaticDescription] = useState(description);
  const [staticPriority, setStaticPriority] = useState(priority);
  const [staticDeadline, setStaticDeadline] = useState(deadline);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedPriority, setEditedPriority] = useState(priority);
  const [editedDeadline, setEditedDeadline] = useState(deadline);  
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

const handleMenuClick = (event) => {
  setMenuAnchorEl(event.currentTarget);
};

const handleMenuClose = () => {
  setMenuAnchorEl(null);
};

const handleEditClick = () => {
  handleMenuClose();
  handleClose();
  handleOpen();
};

const handleDeleteClick = () => {
  axios.put(`${process.env.REACT_APP_API_URL}/del_small_card/${id}`, {}, 
  {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then((response) => {
    console.log(response);
    fetchLargeCards(accessToken, job);
  }, (error) => {
    console.log(error);
    fetchLargeCards(accessToken, job);
  });
  handleClose();
};

  const [{ isDragging }, drag] = useDrag({
    type: 'smallCard',
    item: { id, index, largeCardId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  function handleEditSave(){
    axios.put(`${process.env.REACT_APP_API_URL}/edit_small_card`, {
      id: sCardId,
      title: editedTitle,
      description: editedDescription,
      priority: editedPriority,
      deadline: editedDeadline,
      large_card_id: lCardId,
      position: position
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      console.log(response);
      fetchLargeCards(accessToken, job);
    }, (error) => {
      console.log(error);
      fetchLargeCards(accessToken, job);
    });
  };

  async function updateJob(id, completed) {
    const url = `${process.env.REACT_APP_API_URL}/check_job`;
    try {
      const response = await axios.put(url, {
        id,
        completed
      });
      console.log(response.data);
    } catch (error) {
      console.error(`Error updating job: ${error}`);
    }
  }

  const handleCheckboxChange = (taskID, taskCompleted) => {
    updateJob(taskID, !taskCompleted);
  };

  const handleSave = () => {
    setStaticTitle(editedTitle);
    setStaticDescription(editedDescription);
    setStaticPriority(editedPriority);
    setStaticDeadline(editedDeadline);
    handleEditSave();
    handleClose();
  };

const getColorAndBackground = (priority) => {
  switch (priority) {
    case 'Low':
      return { color: '#29A634', background: 'rgba(142, 177, 37, .3)' };
    case 'Medium':
      return { color: 'rgba(255,116,32,1)', background: 'rgba(240, 183, 38, .5)' };
    case 'High':
      return { color: 'rgba(255, 0, 0, .9)', background: 'rgba(255, 0, 0, .2)' };
    case 'Critical':
      return { color: '#9D3F9D', background: 'rgba(157, 63, 157, .3)' };
    default:
      return { color: '', background: '' };
  }
};

  return (
  <Card className="small-card" ref={ref} style={{ height: '85px' }}>
  <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
   <div>
  {/* <Checkbox
        defaultChecked={task}
        color="success"
        onChange={() => handleCheckboxChange(taskId, task)}
    />  */}
    <Typography style={{ fontSize: '15.5px', fontWeight: '550'}} >{staticTitle}</Typography>
    <Typography style={{ color: '#5F6B7C', fontSize: '13px'}}>{staticDescription}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center' }}>
      {deadline && <Typography variant="body2" style={{ color: '#5F6B7C', marginRight: '30px' }}>{staticDeadline}</Typography>}
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
	      fontWeight: '500',
        alignItems: 'center',
        justifyContent: 'center',
        width: '55px', 
        fontSize: '11.2px',
        borderRadius: '40px',
        padding: '3.2px 9.6px',
        background: getColorAndBackground(staticPriority).background,
        color: getColorAndBackground(staticPriority).color,
        marginRight: '8px',
      }}
    >
      {staticPriority}
    </Box>
    <IconButton onClick={handleMenuClick}>
      <MoreVertIcon />
    </IconButton>
  </div>
</CardContent>
  <Menu
    anchorEl={menuAnchorEl}
    open={Boolean(menuAnchorEl)}
    onClose={handleMenuClose}
  >
  <MenuItem onClick={handleEditClick}>Edit</MenuItem>
  <MenuItem>Notification</MenuItem>
  <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
</Menu>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-small-card-modal"
        aria-describedby="edit-small-card-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="edit-small-card-modal" variant="h6" component="h2">
            Edit Tile
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label="Title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Description"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
          <TextField
            select
            style={{marginTop: '17px'}}
            label="Priority"
            value={editedPriority}
            onChange={(e) => setEditedPriority(e.target.value)}
            fullWidth
          >
	  <MenuItem value="">None</MenuItem>
	  <MenuItem value="Low">Low</MenuItem>
	  <MenuItem value="Medium">Medium</MenuItem>
	  <MenuItem value="High">High</MenuItem>
	  <MenuItem value="Critical">Critical</MenuItem>
	</TextField>
	<TextField
      label="Deadline"
      style={{marginTop: '25px'}}
      type="datetime-local"
      value={editedDeadline}
      onChange={(e) => setEditedDeadline(e.target.value)}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
     <Button style={{marginTop: '20px'}} onClick={handleSave} variant="outlined">
        Save
      </Button>
      <Button style={{marginTop: '20px'}} onClick={handleClose} variant="text" sx={{ marginLeft: 1 }}>
        Cancel
      </Button>
    </Box>
    </Modal>
  </Card>
  );
};

export default SmallCard;

