import {FormControl, TextField} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {Nav} from 'react-bootstrap';
import './AddPastJob.css'

const JobTypeOption = ({
                           JobType, setJobType,

                           freeBulbs, setFreeBulbs,
                           bulbDollars, setBulbDollars,
                           bulbKwReduced, setBulbKwReduced,

                           unitsPtac, setPtacUnits,
                           ptacDollars, setPtacDollars,
                           ptacKwReduced, setPtacKwReduced,

                           freeTherms, setFreeTherms,
                           thermDollars, setThermDollars,
                           thermKwReduced, setThermKwReduced,

                           rebates, setRebates,
                           setGeneralRebateProvided, generalRebateProvided,
                           setGeneralSavings, generalSavings,

                           thermSaleUnits, setThermSaleUnits,
                           thermSaleRebates, setThermSaleRebates,
                           kwReducedThermSale, setKwReducedThermSale,
                       }) => {
    console.log("FREEBULBS", freeBulbs)
    return (
        <div>
            <FormControl fullWidth>
                <Nav variant="pills" defaultActiveKey="General"
                     activeKey={JobType} onSelect={(selectedJobType) => setJobType(selectedJobType)}>
                    <Nav.Item>
                        <Nav.Link eventKey="NetZeroLighting">Net Zero Lighting</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="NetZeroPTACcleaning">Net Zero HVAC Cleaning</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="NetZeroThermostats">Net Zero Thermostats</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="NewBuildIncentives">New Build Incentive</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="ThermostatSale">Thermostat Sale</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="General">General</Nav.Link>
                    </Nav.Item>
                </Nav>
            </FormControl>

            {JobType === 'NetZeroLighting' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Number of bulbs"
                        fullWidth
                        value={freeBulbs}
                        onChange={(e) => setFreeBulbs(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Free bulb dollars"
                        fullWidth
                        value={bulbDollars}
                        onChange={(e) => setBulbDollars(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="KW reduced"
                        fullWidth
                        value={bulbKwReduced}
                        onChange={(e) => setBulbKwReduced(e.target.value)}
                        endAdornment={<InputAdornment position="end">kw</InputAdornment>}
                    />
                </>
            )}
            {JobType === 'NetZeroPTACcleaning' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Number of units cleaned"
                        type="number"
                        fullWidth
                        value={unitsPtac}
                        onChange={(e) => setPtacUnits(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Free cleaning dollars"
                        type="number"
                        fullWidth
                        value={ptacDollars}
                        onChange={(e) => setPtacDollars(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="KW reduced"
                        type="number"
                        fullWidth
                        value={ptacKwReduced}
                        onChange={(e) => setPtacKwReduced(e.target.value)}
                    />
                </>
            )}
            {JobType === 'NetZeroThermostats' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Number of thermostats"
                        type="number"
                        fullWidth
                        value={freeTherms}
                        onChange={(e) => setFreeTherms(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Free thermostat dollars"
                        type="number"
                        fullWidth
                        value={thermDollars}
                        onChange={(e) => setThermDollars(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="KW reduced"
                        type="number"
                        fullWidth
                        value={thermKwReduced}
                        onChange={(e) => setThermKwReduced(e.target.value)}
                    />
                </>
            )}

            {JobType === 'NewBuildIncentives' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Rebate provided"
                        type="number"
                        fullWidth
                        value={rebates}
                        onChange={(e) => setRebates(e.target.value)}
                    />
                </>
            )}

            {JobType === 'ThermostatSale' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Number of thermostats"
                        type="number"
                        fullWidth
                        value={thermSaleUnits}
                        onChange={(e) => setThermSaleUnits(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Rebate provided"
                        type="number"
                        fullWidth
                        value={thermSaleRebates}
                        onChange={(e) => setThermSaleRebates(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="KW reduced"
                        type="number"
                        fullWidth
                        value={kwReducedThermSale}
                        onChange={(e) => setKwReducedThermSale(e.target.value)}
                    />
                </>
            )}
            {JobType === 'General' && (
                <>
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Rebate provided"
                        type="number"
                        fullWidth
                        value={generalRebateProvided}
                        onChange={(e) => setGeneralRebateProvided(e.target.value)}
                    />
                    <TextField
                        style={{marginTop: "1rem"}}
                        label="Savings"
                        type="number"
                        fullWidth
                        value={generalSavings}
                        onChange={(e) => setGeneralSavings(e.target.value)}
                    />
                </>
            )}
        </div>
    );
};
export default JobTypeOption;