import React, { useState } from 'react';
import { Nav, Button} from 'react-bootstrap';
import { TextField } from '@mui/material';
import JobTypeOption from '../AddJobs/JobTypeOption';
import '../AddJobs/AddPastJob.css'
import axios from 'axios';

const EditJob = ({ job, onJobEdited, propertyName, accessToken}) => {
  const [JobType, setJobType] = useState(job.job.job_type || '');
  const [jobID, setJobID] = useState(job.job.id || null);
  const [clientID, setClientID] = useState(job.job.client_id || null);
  const [start_date, setStartDate] = useState(job.job.start_date || '');
  const [end_date, setEndDate] = useState(job.job.bend_date || '');
  const [description, setDescription] = useState(job.job.description || '');
  const [title, setTitle] = useState(job.job.title || '');
  const [revenue, setRevenue] = useState(job.job.revenue || '');
  const [status, setStatus] = useState(job.job.status || 'Not Started');
  const [net_zero_equipment, setNetZeroEquipment] = useState(job.job.net_zero_equipment);
  
  const [bulbID, setBulbID] = useState(job.jobtype?.NetZeroLighting?.id || null);
  const [freeBulbs, setFreeBulbs] = useState(job.jobtype?.NetZeroLighting?.n_free_bulbs || '');
  const [bulbDollars, setBulbDollars] = useState(job.jobtype?.NetZeroLighting?.free_bulb_dollars || '');
  const [bulbKwReduced, setBulbKwReduced] = useState(job.jobtype?.NetZeroLighting?.kw_reduced || '');

  const [ptacID, setPtacID] = useState(job.jobtype?.NetZeroPTACcleaning?.id || null);
  const [unitsPtac, setPtacUnits] = useState(job.jobtype?.NetZeroPTACcleaning?.n_units_cleaned || '');
  const [ptacDollars, setPtacDollars] = useState(job.jobtype?.NetZeroPTACcleaning?.free_cleaning_dollars || '');
  const [ptacKwReduced, setPtacKwReduced] = useState(job.jobtype?.NetZeroPTACcleaning?.kw_reduced || '');

  const [thermID, setThermID] = useState(job.jobtype?.NetZeroThermostats?.id || null);
  const [freeTherms, setFreeTherms] = useState(job.jobtype?.NetZeroThermostats?.n_free_therm || '');
  const [thermDollars, setThermDollars] = useState(job.jobtype?.NetZeroThermostats?.free_therm_dollars || '');
  const [thermKwReduced, setThermKwReduced] = useState(job.jobtype?.NetZeroThermostats?.kw_reduced || '');

  const [rebateID, setRebateID] = useState(job.jobtype?.NewBuildIncentives?.id || null);
  const [rebates, setRebates] = useState(job.jobtype?.NewBuildIncentives?.rebate_provided || '');

  const [thermSaleID, setThermSaleID] = useState(job.jobtype?.ThermostatSale?.id || null);
  const [thermSaleUnits, setThermSaleUnits] = useState(job.jobtype?.ThermostatSale?.n_therm || '');
  const [thermSaleRebates, setThermSaleRebates] = useState(job.jobtype?.ThermostatSale?.rebate_provided || '');
  const [kwReducedThermSale, setKwReducedThermSale] = useState(job.jobtype?.ThermostatSale?.kw_reduced || '');


  console.log("JOB",job)
const handleSubmit = async (e) => {
  e.preventDefault();
  
let jobtype;
switch (JobType) {
  case 'NetZeroLighting':
    jobtype = {
      NetZeroLighting: {
        id: parseInt(bulbID),
        job_id: parseInt(jobID),
        n_free_bulbs: parseInt(freeBulbs),
        free_bulb_dollars: parseFloat(bulbDollars),
        kw_reduced: parseFloat(bulbKwReduced),
      }
    };
    break;
  case 'NetZeroPTACcleaning':
    jobtype = {
      NetZeroPTACcleaning: {
        id: parseInt(ptacID),
        job_id: parseInt(jobID),
        n_units_cleaned: parseInt(unitsPtac),
        free_cleaning_dollars: parseFloat(ptacDollars),
        kw_reduced: parseFloat(ptacKwReduced),
      }
    };
    break;
  case 'NetZeroThermostats':
    jobtype = {
      NetZeroThermostats: {
        id: parseInt(thermID),
        job_id: parseInt(jobID),
        n_free_therm: parseInt(freeTherms),
        free_therm_dollars: parseFloat(thermDollars),
        kw_reduced: parseFloat(kwReducedThermSale),
      }
  };
    break;
  case 'NewBuildIncentives':
    jobtype = {
      NewBuildIncentives: {
        id: parseInt(rebateID),
        job_id: parseInt(jobID),
        rebate_provided: parseFloat(rebates),
      }
    };
    break;
  case 'ThermostatSale':
    jobtype = {
      ThermostatSale: {
        id: parseInt(thermSaleID),
        job_id: parseInt(jobID),
        n_therm: parseInt(thermSaleUnits),
        rebate_provided: parseFloat(thermSaleRebates),
        kw_reduced: parseFloat(kwReducedThermSale),
      }
    };
    break;
}

  const newJob = {
    job: {
      id: parseInt(jobID),
      property_name: propertyName,
      client_id: parseInt(clientID),
      title: title,
      description: description,
      status: status,
      job_type: JobType,
      start_date: formatDate(start_date) || null,
      end_date: formatDate(end_date) || null,
      revenue: parseFloat(revenue) || null,
      net_zero_equipment: net_zero_equipment,
    },
      jobtype,
  }; 

  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/edit_job`, newJob, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    );
    const editedJob = { ...newJob, id: response.data };
    onJobEdited(editedJob);
    resetForm();
  } catch (error) {
    console.error("Error submitting new job:", error);
  }
};

const formatDate = (dateString) => {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  };

const resetForm = () => {
  setStartDate('');
  setDescription('');
};

const renderJobStatusNavbar = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Nav variant="pills" activeKey={status} onSelect={(selectedStatus) => setStatus(selectedStatus)}>
        <Nav.Item>
          <Nav.Link eventKey="Not Started">Not Started</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="In Progress">In Progress</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Completed">Completed</Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};

return (
  <div className="d-flex justify-content-center align-items-center" style={{minHeight: '70vh'}}>
    <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '80%', border: 'none' }}>
      <JobTypeOption 
        JobType={JobType} 

        freeBulbs={freeBulbs}
        bulbDollars={bulbDollars}
        bulbKwReduced={bulbKwReduced}

        unitsPtac={unitsPtac}
        ptacDollars={ptacDollars}
        ptacKwReduced={ptacKwReduced}

        freeTherms={freeTherms}
        thermDollars={thermDollars}
        thermKwReduced={thermKwReduced}

        rebates={rebates}

        thermSaleUnits={thermSaleUnits}
        thermSaleRebates={thermSaleRebates}
        kwReducedThermSale={kwReducedThermSale}

        setJobType={setJobType}
        setFreeBulbs={setFreeBulbs}
        setBulbDollars={setBulbDollars}
        setBulbKwReduced={setBulbKwReduced}

        setPtacUnits={setPtacUnits}
        setPtacDollars={setPtacDollars}
        setPtacKwReduced={setPtacKwReduced}

        setFreeTherms={setFreeTherms}
        setThermDollars={setThermDollars}
        setThermKwReduced={setThermKwReduced}

        setRebates={setRebates}

        setThermSaleUnits={setThermSaleUnits}
        setThermSaleRebates={setThermSaleRebates}
        setKwReducedThermSale={setKwReducedThermSale}
      />
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={job.job.start_date}
        onChange={(e) => setStartDate(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={job.job.end_date}
        onChange={(e) => setEndDate(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <TextField
        label="Revenue"
        type="number"
        value={revenue}
        onChange={(e) => setRevenue(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <TextField
        label="Net Zero Equipment"
        type="number"
        value={net_zero_equipment}
        onChange={(e) => setNetZeroEquipment(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <div style={{width: '100%',marginTop: '1rem'}}>
      {renderJobStatusNavbar()}
      </div>
      <TextField
        label="Description"
        multiline
        rows={5}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        style={{marginTop: "1rem"}}
      />
      <Button style={{marginTop: "1rem"}}
            onClick={handleSubmit}
          className="button-add custom-add-job-btn" variant="contained" color="primary" type="submit">
        Edit Job
      </Button>
    </form>
  </div>
 );
}
export default EditJob;