import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "../App.css";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { utils, writeFile } from 'xlsx';
import Typography from '@mui/material/Typography';
import DataTable from "./leadGrid";
import Grid from '@mui/material/Grid';
import { Container } from "react-bootstrap";
import axios from "axios";
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
    useQueryClient
} from "@tanstack/react-query";

const queryClient = new QueryClient();

function GetLeads( inputValue ) { 
    return useQuery({
      queryKey: ["lead_gen"],
      queryFn: async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/gen_leads/${inputValue}`);
        return data;
      },
    });
  }

  export default function LeadPage() {
    const [inputValue, setInputValue] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState([]); 
    
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
          setChatHistory([...chatHistory, inputValue]);
          setInputValue("");
          setLoading(true);
          const ai_response = await axios.post(`${process.env.REACT_APP_API_URL}/ai_leader`, 
          {
            search_term: inputValue
          });
          setChatHistory([...chatHistory, ai_response.data]);

          const response = await axios.post(`${process.env.REACT_APP_API_URL}/gen_leads`, {
              search_term: inputValue
            });
          setLoading(false);
          setResponse(response.data);  // Saving response data
        }
      }

  return (
    <div className="app-root alliance-font" style={{ minHeight: "100vh", backgroundColor: "#EDEFF2"}}>
      <Box sx={{paddingTop: '5rem', paddingLeft: '10%', flexDirection: "column", height: "100%", Width: "100%" }}>
      <Grid container spacing={1}>
      <Grid item xs={4}>
        <OutlinedCard chatHistory={chatHistory} />
        <TextField 
          label="Ask for leads then press enter" 
          variant="filled" 
          sx={{ width: '100%', flex: '1' }} 
          value={inputValue} 
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} 
        />
        </Grid>
        <Grid item xs={8}>
        <DataTable response={response} loading={loading}/>
        </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#EDEFF2",
          padding: 2,
        }}
      >
        <Box sx={{ width: '40%', marginBottom: 3, display: 'flex', alignItems: 'center' }}>
        </Box>
      </Box>
   </div>
  );
}

function OutlinedCard({ chatHistory }) {
    return (
      <Box sx={{marginBottom: 3, display: 'flex', alignItems: 'center' }}>
        <Card sx={{width: '400em', height: '41em', overflow: 'auto'}} variant="outlined">
          {chatHistory.map((chat, index) => (
            <CardContent key={index}>
              <Typography variant="body2" color="text.secondary">
                {chat}
              </Typography>
            </CardContent>
          ))}
        </Card>
      </Box>
    );
  }
  function exportToExcel(rows, columns) {
    const data = rows.map((row) => 
      columns.map((column) => 
        row[column.field]
      )
    );
  
    data.unshift(columns.map((column) => column.headerName));
  
    const worksheet = utils.aoa_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    writeFile(workbook, "data_grid.xlsx");
  }
