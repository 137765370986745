import React, {useCallback, useEffect, useState} from "react";
import {Col, Nav} from "react-bootstrap";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import ContactGrid from "./contactGrid";
import LargeCard from "./largecard";
import update from "immutability-helper";
import {debounce} from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import PropertyDashboard from "../Property/PropertyDashboard";

export const fetchPastJobsById = async (clientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/job_by_id/${clientId}`);
        console.log("fetchPastJobsById", clientId);
        return response.data;
    } catch (error) {
        console.error("Error fetching past jobs:", error);
        throw error;
    }
};

const ProjectsDrawer = ({open, onClose, job, Client, accessToken}) => {
    const [largeCards, setLargeCards] = useState([]);
    const {user} = useAuth0();
    const [client, setClient] = useState({});
    const [loading, setLoading] = useState(false);
    const [pastJobs, setPastJobs] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [largeCardTitle, setLargeCardTitle] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [status, setStatus] = useState("Manager");
    const [jobid, setJobid] = useState(null);

    const addLargeCard = async (title, jobId) => {
        console.log("ADD LARGE CARD", title, jobId);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_large_card`, {
            title: title, job_id: jobId,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const lcardID = response.data;
        const newLargeCard = {
            large_cards: {
                title: title, id: lcardID, job_id: jobId,
            }, small_cards: [],
        };
        setLargeCards([...largeCards, newLargeCard]);
        handleModalClose();
    };

    const getClientDetails = async (accessToken, client_id) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/client_by_id/${client_id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const client = response.data;

        console.log("CLIENT", client);
        setClient(client);
    };

    const projNavbar = () => {
        return (<div
            style={{
                display: "flex",
                justifyContent: "start",
                marginLeft: "1rem",
                marginTop: ".2rem",
                marginBottom: ".7rem ",
            }}
        >
            <Nav
                style={{width: "100%", color: "#4C90F0"}}
                variant="pills"
                activeKey={status}
                onSelect={(selectedStatus) => setStatus(selectedStatus)}
            >
                <Nav.Item style={{display: "flex", marginRight: ".1rem"}}>
                    <Nav.Link style={{textAlign: "center"}} eventKey="Manager">
                        Manager
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{display: "flex", marginRight: ".1rem"}}>
                    <Nav.Link style={{textAlign: "center"}} eventKey="Notes">
                        Notes
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{display: "flex", marginRight: ".1rem"}}>
                    <Nav.Link style={{textAlign: "center"}} eventKey="Contacts">
                        Contacts
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>);
    };

    async function fetchLargeCards(accessToken, job_id) {

        console.log("FETCHING LARGE CARDS", job_id);

        const realJobId = job_id.id ? job_id.id : job_id;

        console.log("real job id", realJobId);

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_large_card_by_id/${realJobId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const fetchedLargeCards = response.data;

        const largeCardsWithSmallCards = fetchedLargeCards.map((largeCard) => {
            const {small_cards} = largeCard;
            return {
                ...largeCard, small_cards: small_cards.map((smallCard) => ({
                    id: smallCard.id,
                    title: smallCard.title,
                    description: smallCard.description,
                    large_card_id: smallCard.large_card_id,
                    position: smallCard.position,
                    priority: smallCard.priority,
                    deadline: smallCard.deadline,
                })),
            };
        });

        console.log("largeCardsWithSmallCards", largeCardsWithSmallCards);

        setLargeCards(largeCardsWithSmallCards);
    }

    const addSmallCard = (smallCardId, largeCardId) => {
        const largeCardIndex = largeCards.findIndex((card) => card.id === largeCardId);
        const smallCard = largeCards
            .map((card) => card.smallCards)
            .flat()
            .find((card) => card.id === smallCardId);

        const newSmallCards = update(largeCards[largeCardIndex].smallCards, {
            $push: [smallCard],
        });

        const newLargeCards = update(largeCards, {
            [largeCardIndex]: {smallCards: {$set: newSmallCards}},
        });

        setLargeCards(newLargeCards);
    };

    const addSmallCardToLargeCard = async (job_id, largeCardId, title, description, priority, deadline, user_id) => {
        const newLargeCard = [...largeCards];
        const largeCardIndx = newLargeCard.findIndex((card) => card.large_cards.id === largeCardId);
        const largeCard = newLargeCard[largeCardIndx];
        const newCard = {
            title, description, large_card_id: largeCardId, priority, deadline,
        };

        if (largeCard) {
            // Remove old small card
            largeCard.small_cards = largeCard.small_cards.filter((card) => card.id !== newCard.id);
            // Add new small card
            largeCard.small_cards.push(newCard);
        }
        newLargeCard[largeCardIndx] = largeCard;
        setLargeCards(newLargeCard);

        const uid = user_id.split("|")[1];
        const response = await fetch(`${process.env.REACT_APP_API_URL}/create_small_card`, {
            method: "POST", headers: {
                "Content-Type": "application/json", Authorization: `Bearer ${accessToken}`,
            }, body: JSON.stringify({
                new_card: {
                    title, description, large_card_id: largeCardId, priority, deadline, user_id: uid,
                }, job_id: job_id,
            }),
        });

        const newSmallCardId = await response.json();
        const newSmallCard = {
            id: newSmallCardId, title, description, large_card_id: largeCardId, priority, deadline,
        };

        const largeCardIndex = largeCards.findIndex((card) => card.large_cards.id === largeCardId);

        // Remove old small card
        const oldSmallCards = largeCards[largeCardIndex].small_cards;
        const newSmallCards = oldSmallCards.filter((card) => card.id !== newCard.id);

        // Add new small card
        newSmallCards.push(newSmallCard);

        const newLargeCards = update(largeCards, {
            [largeCardIndex]: {small_cards: {$set: newSmallCards}},
        });

        setLargeCards(newLargeCards);
    };

    const updateLargeCardsState = (updatedLargeCards) => {
        setLargeCards(updatedLargeCards);
    };

    const updateSmallCardLargeCardId = async (smallCardId, newLargeCardId, newPosition) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/update_small_card_large_card_id`, {
            method: "PUT", headers: {
                "Content-Type": "application/json", Authorization: `Bearer ${accessToken}`,
            }, body: JSON.stringify({
                small_card_id: smallCardId, large_card_id: newLargeCardId, new_position: newPosition,
            }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`Error updating small card large card ID: ${response.status} ${response.statusText} - ${errorText}`);
            throw new Error("Failed to update small card large card ID");
        }
    };

    const getUpdatedSmallCardsOrder = (largeCardId) => {
        const largeCard = largeCards.find((card) => card.id === largeCardId);
        if (largeCard) {
            return largeCard.smallCards.map((smallCard, index) => [smallCard.id, index,]);
        } else {
            return [];
        }
    };

    const handleBackClick = () => {
        setPastJobs([]);
        setLargeCardTitle("");
        setSelectedProperty(null);
    };

    const moveSmallCard = (smallCardId, fromLargeCardId, toLargeCardId, newIndex, updatedSmallCardsOrder, dragIndex, hoverIndex, largeCardId) => {
        setLargeCards((prevLargeCards) => {
            const fromLargeCardIndex = prevLargeCards.findIndex((largeCard) => largeCard.large_cards.id === fromLargeCardId);
            const toLargeCardIndex = prevLargeCards.findIndex((largeCard) => largeCard.large_cards.id === toLargeCardId); //**api send LARGE CARD ID

            if (fromLargeCardIndex === -1 || toLargeCardIndex === -1) {
                return prevLargeCards;
            }

            const fromSmallCards = prevLargeCards[fromLargeCardIndex].small_cards;
            const toSmallCards = prevLargeCards[toLargeCardIndex].small_cards;

            const smallCardIndex = fromSmallCards.findIndex((smallCard) => smallCard.id === smallCardId); //prevsmallcard Index
            if (smallCardIndex === -1) {
                return prevLargeCards;
            }

            const smallCard = fromSmallCards[smallCardIndex]; //prevsmallcard

            if (fromLargeCardId === toLargeCardId) {
                // Reorder small cards within the same large card
                const newSmallCards = [...fromSmallCards];
                newSmallCards.splice(smallCardIndex, 1);
                newSmallCards.splice(newIndex, 0, smallCard);

                const newLargeCards = prevLargeCards.map((largeCard) => {
                    if (largeCard.large_cards.id === fromLargeCardId) {
                        return {...largeCard, small_cards: newSmallCards};
                    } else {
                        return largeCard;
                    }
                });

                updateLargeCardsState(newLargeCards);
                return newLargeCards;
            } else {
                const newFromSmallCards = [...fromSmallCards.slice(0, smallCardIndex), ...fromSmallCards.slice(smallCardIndex + 1),]; //updates small cards from og large card

                const newToSmallCards = [...toSmallCards];
                newToSmallCards.splice(newIndex, 0, smallCard); //updates small cards from new large card

                const newLargeCards = prevLargeCards.map((largeCard) => {
                    if (largeCard.large_cards.id === fromLargeCardId) {
                        return {...largeCard, small_cards: newFromSmallCards};
                    } else if (largeCard.large_cards.id === toLargeCardId) {
                        return {...largeCard, small_cards: newToSmallCards};
                    } else {
                        return largeCard;
                    }
                });

                return newLargeCards;
            }
        });

        if (fromLargeCardId !== toLargeCardId) {
            updateSmallCardLargeCardId(smallCardId, toLargeCardId);
        }
    };
    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handleRemoveSmallCard = (id) => {
        const largeCards = [...largeCards];
        const largeCardIndex = largeCards.findIndex((card) => card.large_cards.id === id);
    };

    const handlePropertyClick = async (property) => {
        setSelectedProperty(property);
    };

    useEffect(() => {
        const fetchJob = async () => {
            // if (job) {
            const resolvedJob = await job;
            console.log("Resolved JOB", resolvedJob);
            const client_id = resolvedJob?.job?.client_id || resolvedJob.client_id;
            setJobid(resolvedJob.job ? resolvedJob.job.id : resolvedJob.id);
            console.log("CLIENTID", client_id);
            const jobIdClone = resolvedJob.job ? resolvedJob.job.id : resolvedJob.id;
            await fetchLargeCards(accessToken, jobIdClone);
            await getClientDetails(accessToken, client_id);
            // }
        };
        fetchJob();
    }, [job]);

    function handleDrawerClose() {
        setTabValue(0);
        setClient({});
        setPastJobs([]);
        setLargeCards([]);
        onClose();
    }

    function handleSetTabContact() {
        setTabValue(2);
    }

    function handleSetTabNote() {
        setTabValue(1);
    }

    function handleSetTabManager() {
        setTabValue(0);
    }

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    console.log("LOADING", loading);

    return (<Drawer
        anchor="bottom"
        open={open}
        onClose={handleDrawerClose}
        PaperProps={{
            sx: {
                height: "85%", borderTopLeftRadius: 2, borderTopRightRadius: 2,
            },
        }}
    >
        <div style={{height: "100%", width: "100%", display: "flex"}}>
            {(<div
                style={{
                    marginTop: "5%",
                    marginLeft: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                }}
            >
                <CircularProgress/>
            </div>) && loading === true}
            {loading === false && (<>
                <div style={{flex: "25%"}}>
                    <Box style={{width: "20rem", marginLeft: ".2rem"}} p={2}>
                        {job && (<h1 variant="h6" component="div" style={{paddingLeft: 16}}>
                            {job.title}
                        </h1>)}
                        {job && (<Typography
                            variant="body2"
                            color="text.secondary"
                            style={{paddingLeft: 16}}
                        >
                            {job.description}
                        </Typography>)}
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{paddingLeft: 16}}
                        >
                            <p>{client.property_name}</p>
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                            style={{paddingLeft: 16}}
                        >
                            <p>
                                {" "}
                                NAM: <strong>{client.account_manager}</strong>
                            </p>
                        </Typography>
                        <Button
                            onClick={() => handlePropertyClick(client)}
                            style={{fontSize: ".75rem", width: "100%"}}
                            variant="outlined"
                            color="primary"
                        >
                            Property Details
                        </Button>
                        {selectedProperty && (<Col md={4}>
                            <PropertyDashboard
                                onClose={handleBackClick}
                                iD={selectedProperty.id}
                                accessToken={accessToken}
                            />
                        </Col>)}
                    </Box>
                </div>
                <div style={{flex: "85%"}}>
                    <Dialog open={openModal} onClose={handleModalClose}>
                        <DialogTitle style={{minWidth: "22vw"}}>Add Tile</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="largeCardTitle"
                                label="enter tile name"
                                type="text"
                                fullWidth
                                value={largeCardTitle}
                                onChange={(e) => setLargeCardTitle(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleModalClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => addLargeCard(largeCardTitle, jobid)}
                                color="primary"
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {job && (<Box p={2} overflow="auto">
                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                        {projNavbar()}
                        {/* <Tabs aria-label="basic tabs example">
        <Tab label="Manager" onClick={handleSetTabManager} />
        <Tab label="Notes" onClick={handleSetTabNote} />
        <Tab label="Contacts" onClick={handleSetTabContact} />
      </Tabs> */}
                        {/* </Box> */}
                        {status === "Manager" && (<div
                            style={{
                                display: "flex", flexDirection: "row", overflowX: "auto", alignItems: "start",
                            }}
                        >
                            {largeCards.map((largeCard) => (<LargeCard
                                key={largeCard.large_cards.id}
                                iD={largeCard.large_cards.id}
                                title={largeCard.large_cards.title}
                                jobid={largeCard.large_cards.job_id}
                                smallCards={largeCard.small_cards || []}
                                largeCards={largeCards}
                                moveSmallCard={moveSmallCard}
                                addSmallCardToLargeCard={addSmallCardToLargeCard}
                                getUpdatedSmallCardsOrder={getUpdatedSmallCardsOrder}
                                updateLargeCardsState={updateLargeCardsState}
                                updateSmallCardLargeCardId={updateSmallCardLargeCardId}
                                fetchLargeCards={fetchLargeCards}
                                job={job}
                                accessToken={accessToken}
                                user_id={user.sub}
                            />))}
                            <IconButton
                                style={{
                                    marginTop: "30vh", marginLeft: "6vw", height: "5rem", width: "5rem",
                                }}
                                onClick={handleModalOpen}
                                color="primary"
                                aria-label="add large card"
                            >
                                <AddIcon/>
                            </IconButton>
                        </div>)}
                        {status === "Notes" && jobid && (<Notepad job_id={jobid} accessToken={accessToken}/>)}
                        {status === "Contacts" && jobid && (<Contacts jobId={jobid} accessToken={accessToken}/>)}
                    </Box>)}
                </div>
            </>)}
        </div>
    </Drawer>);
};

export default ProjectsDrawer;

function Contacts({accessToken, jobId}) {
    return (<div>
        <ContactGrid accessToken={accessToken} jobId={jobId}/>
    </div>);
}

function Notepad({job_id, accessToken}) {
    console.log("NOTEPAD", job_id);
    console.log("NOTEPAD", accessToken);
    const [text, setText] = useState("");
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/get_note/${job_id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                setText(response.data.text_data);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }, [job_id, accessToken]);

    const saveNote = useCallback(debounce((newText) => {
        // replace '/api/insert_note' with your API endpoint
        axios
            .put(`${process.env.REACT_APP_API_URL}/insert_note`, {text_data: newText, job_id: job_id}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }, 200), [job_id]);

    const handleChange = (event) => {
        setText(event.target.value);
        saveNote(event.target.value);
    };

    return (<Card variant="outlined">
        <CardContent>
            <Typography variant="h5" component="h2">
                Notes
            </Typography>
            <TextField
                variant="standard"
                sx={{height: "100vh"}}
                multiline
                rows={100}
                fullWidth
                value={text}
                onChange={handleChange}
            />
        </CardContent>
    </Card>);
}
