import axios from "axios";


export const fetchClientContacts = async (propertyId, token) => {
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/client_contacts/${propertyId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {data, error: null};
    } catch (err) {
        return {data: null, error: err.message};
    }
};

export async function createClientContact(newContact, accessToken) {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_client_contact`, newContact, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return response.data
}

export async function editClientContact(contact, accessToken) {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/edit_client_contact`, contact, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
}

export async function deleteClientContact(contactId, accessToken) {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/client_contact/${contactId}`,{
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
}