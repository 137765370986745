import React, { useState } from "react";
import "./Search.css";
import { Form, FormControl } from "react-bootstrap";

export default function Search ({ setSearch }){
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearch(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <Form inline="true" onSubmit={handleSubmit}>
      <div className="search-wrapper">
        <FormControl
          type="text"
          style={{}}
          placeholder="Search For Property"
          className="mr-sm-2"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
      </div>
    </Form>
  );
};



