import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import "../../index.css";
import { useQuery } from "@tanstack/react-query";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ProjectsDrawer from "./projectdrawer";
import CircularProgress from "@mui/material/CircularProgress";

function ProjectManager({ setTaskJob, user, job, value, token, jobSearch }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [accessToken] = useState(token);

  let query = {};

  console.log("queryD", value);

  useEffect(() => {
    if (job) {
      toggleDrawer(job);
    }
  }, []);

  if (jobSearch && value === "2") {
    console.log("JOBSEARCH", jobSearch);
    console.log("TOKEJS", accessToken);
    query = {
      queryKey: ["Job Search", jobSearch],
      queryFn: async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/job_search/${jobSearch}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return data;
      },
    };
  }

  if (!jobSearch && value === "2") {
    console.log(accessToken);
    const uuid = user.sub.split("|")[1];
    query = {
      queryKey: ["JOB"],
      queryFn: async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/all_jobs/${uuid}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return data;
      },
    };
  }

  const getStatusStyles = (status) => {
    switch (status) {
      case "Completed":
        return {
          color: "#4caf50",
          fontSize: "0.8rem",
          backgroundColor: "rgba(76, 175, 80, 0.2)",
        };
      case "In Progress":
        return {
          color: "#ff9800",
          fontSize: "0.8rem",
          backgroundColor: "rgba(255, 152, 0, 0.2)",
        };
      case "Not Started":
        return {
          color: "#f44336",
          fontSize: "0.8rem",
          backgroundColor: "rgba(244, 67, 54, 0.2)",
        };
      default:
        return {};
    }
  };

  const renderCardFields = (job) => {
    const cards = [];
    for (let i = 1; i <= 6; i++) {
      const card = job[`card_${i}`];
      if (card) {
        cards.push(
          <React.Fragment key={`card_${i}`}>
            <ListItem>
              <ListItemText primary={card} />
            </ListItem>
            {i !== 6 && <Divider style={{ backgroundColor: "#111418" }} />}
          </React.Fragment>
        );
      }
    }
    return <List>{cards}</List>;
  };

  const toggleDrawer = (job_val) => {
    console.log("job_val", job_val);
    setSelectedJob(job_val);
    setDrawerOpen(!drawerOpen);
  };

  const toggleDrawerClose = () => {
    setTaskJob(null);
    setSelectedJob(null);
    setDrawerOpen(!drawerOpen);
  };

  const { data, error, refetch, isLoading } = useQuery(query);

  if (isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }
  if (data) {
    return (
      <div>
        <div className="width: 100%"></div>
        <Grid container spacing={2}>
          {data.map((job) => (
            <Grid item xs={12} sm={6} md={4} key={job.id}>
              <Card
                style={{
                  flex: 1,
                  marginTop: "1rem",
                  padding: "1rem",
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "coloumn",
                  }}
                >
                  <Box
                    sx={{
                      width: "6.em",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "12px",
                      px: ".5rem",
                      py: ".2rem",
                      ...getStatusStyles(job.job.status),
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: "inline-block",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "currentColor",
                        marginRight: 0.5,
                      }}
                    />
                    {job.job.status}
                  </Box>
                </div>
                <Typography sx={{ flex: 1 }} variant="h5" component="h2">
                  <h1 style={{ fontSize: "1em" }}> {job.job.title} </h1>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                  }}
                >
                  <Box>
                    <Typography
                      style={{ marginBottom: ".5rem" }}
                      variant="body1"
                      component="p"
                    >
                      {job.job.property_name}
                    </Typography>
                    <Typography variant="body2" component="h1">
                      Job Type: {job.job.job_type}
                    </Typography>
                    <Typography variant="body2" component="p">
                      Date: {job.job.start_date}
                    </Typography>
                    <Typography variant="body2" component="p">
                      Description: {job.job.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        color: "#2D72D2",
                        marginTop: ".9rem",
                        borderRadius: 4,
                        fontSize: "0.8rem",
                        padding: "4px 8px",
                        minWidth: "unset",
                      }}
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      onClick={() => toggleDrawer(job.job)}
                    >
                      Project View
                    </Button>
                  </Box>
                </Box>
                {renderCardFields(job)}
              </Card>
            </Grid>
          ))}
        </Grid>
        <ProjectsDrawer
          open={drawerOpen}
          job={selectedJob}
          onClose={() => toggleDrawerClose()}
          accessToken={accessToken}
        />
      </div>
    );
  }
}

export default ProjectManager;
