import React from "react";
import CRM from "./CRM";
import Clients from "./Clients/Clients";
import "./App.css";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { Routes, Route } from "react-router-dom";
import { CallbackPage } from "./callback";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/CRM" element={<AuthenticationGuard component={CRM} />} />
        <Route path="home" element={<Clients />} />
        <Route path="/callback" element={<CallbackPage />} />
        <Route
          path="clients"
          element={<AuthenticationGuard component={CRM} />}
        />
      </Routes>
    </div>
  );
};
export default App;
