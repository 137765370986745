import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Modal, Form } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const AddPropertyModal = ({ onPropertyAdded, showAddPropertyModal, handleAddPropertyModalClose, accessToken}) => {
  const [propertyDetails, setPropertyDetails] = useState({});

  const handleChange = (event) => {
    setPropertyDetails({
      ...propertyDetails,
      [event.target.name]: event.target.value,
    });
  };

const handleSubmit = async () => {
  console.log("ACCESS TOKEN", accessToken);
  try {
    const propertyData = {
      ...propertyDetails,
      zip: parseInt(propertyDetails.zip, 10),
      rooms: parseInt(propertyDetails.rooms, 10), 
      client_id: parseInt('2',10), 
    };
    handleAddPropertyModalClose();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/create_client`,
      propertyData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // onPropertyAdded(response.data);
  } catch (error) {
    console.error("Error adding property:", error);
    if (error.response) {
      console.error("Error response data:", error.response.data);
    }
  }
};
  return (
    <Modal style={{minWidth: '100vw' }}show={showAddPropertyModal} onHide={handleAddPropertyModalClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title><h1>Add New Property</h1></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <Grid container>
      <Grid item xs={12} md={6}>
      <TextField
          id="property_name"
          label="Property Name"
          type="text"
          name="property_name"
          onChange={handleChange}
          placeholder="Enter property name"
          fullWidth
          variant="outlined"
          style={{ width: '98%', marginTop: '0rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
        /> 
        <TextField
            id="utility"
            label="Utility"
              type="text"
              name="utility"
              onChange={handleChange}
              placeholder="Enter GM name"
              fullWidth
              variant='outlined'
              style={{ width: '98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="gm_name"
            label="GM Name"
            type="text"
            name="gm_name"
            onChange={handleChange}
            placeholder="Enter GM name"
            fullWidth
 		        variant="outlined"
            style={{ width: '98%', marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
          />
          <TextField
            id="phone"
            label="Phone"
              type="text"
              name="phone"
              onChange={handleChange}
              placeholder="Enter phone number"
              fullWidth
              variant='outlined'
              style={{ width:'98%' ,marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="gm_email"
            label="Email"
              type="text"
              name="gm_email"
              onChange={handleChange}
              fullWidth
              placeholder="Enter city"
              variant='outlined'
              style={{ width: '98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
            <TextField
            id="account_manager"
            label="Account Manager"
              type="text"
              name="account_manager"
              onChange={handleChange}
              placeholder="Enter Account Manager"
              fullWidth
              variant='outlined'
              style={{ width: '98%', marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
            <TextField
              id="legal_entity"
              label="Legal Entity"
              type="Legal Entity"
              name="legal_entity"
              onChange={handleChange}
              placeholder="Enter Legal Entity"
              fullWidth
              variant='outlined'
              style={{ width: '98%', marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
            </Grid>
              <Grid item xs={12} md={6}>
              <TextField
            id="address"
            label="Address"
              type="text"
              name="address"
              onChange={handleChange}
              placeholder="Enter address"
              fullWidth
              variant='outlined'
              style={{ width:'98%', marginTop: '.0rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
            <TextField
            id="state"
            label="State"
              type="text"
              name="state"
              onChange={handleChange}
              placeholder="Enter state"
              fullWidth
              variant='outlined'
              style={{ width: '98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="zip"
            label="Zip"
              type="text"
              name="zip"
              onChange={handleChange}
              placeholder="Enter zip code"
              fullWidth
              variant='outlined'
              style={{ width: '98%', marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="rooms"
            label="Rooms"
              type="number"
              name="rooms"
              onChange={handleChange}
              placeholder="Enter number of rooms"
              fullWidth
              variant='outlined'
              style={{ width: '98%', marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="brandcode"
            label="Brand Code"
              type="text"
              name="brandcode"
              onChange={handleChange}
              placeholder="Enter brand code"
              fullWidth
              variant='outlined'
              style={{ width: '98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="ownership"
            label="Ownership"
              type="text"
              name="brand"
              onChange={handleChange}
              placeholder="Enter Ownership"
              fullWidth
              variant='outlined'
              style={{ width: '98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
          <TextField
            id="managing_group"
            label="Managing Group"
              type="text"
              name="chain"
              onChange={handleChange}
              placeholder="Enter Managing Group"
              fullWidth
              variant='outlined'
              style={{ width:'98%',marginTop: '.5rem', marginBottom: '.5rem', borderColor: '#ABB2BF'}}
            />
            </Grid>
            </Grid>
        <Button variant="primary" style={{marginTop: '.5rem', width: '98%'}}onClick={handleSubmit}>
          Add Property
        </Button>
        </Modal.Body>
    </Modal>
  );
};

export default AddPropertyModal;
