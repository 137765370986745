import React, {useEffect} from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

function makeDealsData(accessToken, realData, type) {

    const label = type === "dealsClosed" ? "# of Deals" : "Revenue"
    return {
        options: {scales: {scaleLabel: {fontSize: 1}}},
        labels: [
            'Net Zero Lighting',
            'Net Zero HVAC cleaning',
            'Net Zero Thermostats',
            'New Build Incentives',
            'Thermostat Sale',
            'General'
        ],
        datasets: [
            {
                label,
                data: [
                    realData.net_zero_lighting,
                    realData.net_zero_ptac_cleaning,
                    realData.net_zero_thermostats,
                    realData.new_build_insentives,
                    realData.thermostat_sale,
                    realData.general,
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
}

async function getTotalDealsClosed(accessToken) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_total_deals_closed`, {
            headers: {
                "Content-Type": "application/json", Authorization: `Bearer ${accessToken}`,
            },
        });
        console.log("getRevenueByService", response.data);
        return response.data
    } catch (error) {
        console.error(`Error updating job: ${error}`);
    }
}

async function getTotalDealRevenue(accessToken) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_total_deal_revenue`, {
            headers: {
                "Content-Type": "application/json", Authorization: `Bearer ${accessToken}`,
            },
        });
        console.log("getRevenueByService", response.data);
        return response.data
    } catch (error) {
        console.error(`Error updating job: ${error}`);
    }
}

export const data = {
    options: {scales: {scaleLabel: {fontSize: 1}}},
    labels: [
        'Net Zero Lighting',
        'Net Zero HVAC cleaning',
        'Net Zero Thermostats',
        'New Build Incentives',
        'Thermostat Sale',
        'General'
    ],
    datasets: [
        {
            label: '# of Deals',
            data: [
                12,
                19,
                3,
                5,
                2,
                3
            ],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const options = {
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 9
                }
            }
        }
    }
};


export default function Pie({accessToken, type}) {
    const [chart, setChart] = React.useState(undefined)

    console.log("type", type)
    console.log("ACODE", accessToken)
    useEffect(() => {
        const fetchData = async () => {
            let realData;
            if (type === 'dealsClosed') {
                realData = await getTotalDealsClosed(accessToken)
            } else if (type === 'dealRevenue') {
                realData = await getTotalDealRevenue(accessToken)
            }

            const data = makeDealsData(accessToken, realData, type)
            setChart(<Doughnut options={options} data={data}/>);
        }
        fetchData()
    }, [type]);

    return chart
}
