import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import {createClientContact, deleteClientContact, editClientContact, fetchClientContacts} from "./api";
import CircularProgress from "@mui/material/CircularProgress";


export default function PropertyContacts({uuid, token, propertyId}) {
    const [contacts, setContacts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getContacts = async () => {
            setIsLoading(true);
            try {
                const {data, error} = await fetchClientContacts(propertyId, token);
                if (error) {
                    throw new Error(error);
                }
                setContacts(data || []); // Ensure we always set an array, even if data is null
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        getContacts();
    }, [propertyId, token]);

    const handleSave = (updatedContact) => {
        setContacts(prevContacts =>
            prevContacts.map(contact =>
                contact.id === updatedContact.id ? updatedContact : contact
            )
        );
    };

    const handleDelete = (deletedContactId) => {
        setContacts(prevContacts =>
            prevContacts.filter(contact => contact.id !== deletedContactId)
        );
    };

    const handleAddNew = async () => {
        const newContact = {
            client_id: propertyId,
            name: "",
            title: "",
            phone: "",
            email: "",
        };

        try {
            newContact.id = await createClientContact(newContact, token);
            setContacts(prevContacts => [...prevContacts, newContact]);
        } catch (err) {
            console.error("Error adding new contact:", err);
        }
    };

    if (isLoading) {
        return <CircularProgress/>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Button
                style={{
                    float: "left",
                    width: "100%",
                    marginBottom: "2%",
                }}
                onClick={handleAddNew}
                variant="outlined"
            >
                Add New Contact
            </Button>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contacts && contacts.length > 0 ? (
                            contacts.map(contact => (
                                <PropertyContactRow
                                    key={contact.id}
                                    client_contact={contact}
                                    onSave={handleSave}
                                    onDelete={handleDelete}
                                    token={token}
                                />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">No contacts found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}


export const PropertyContactRow = ({client_contact, onSave, onDelete, token}) => {
    const [editing, setEditing] = useState(false);
    const [editedContact, setEditedContact] = useState(client_contact);
    const [error, setError] = useState(null);

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = async () => {
        try {
            await editClientContact(editedContact, token);
            onSave(editedContact);
            setEditing(false);
            setError(null);
        } catch (err) {
            console.error("Error updating contact:", err);
            setError("Failed to update contact. Please try again.");
        }
    };

    const handleCancel = () => {
        setEditedContact(client_contact);
        setEditing(false);
        setError(null);
    };

    const handleDelete = async () => {
        try {
            await deleteClientContact(client_contact.id, token);
            onDelete(client_contact.id);
        } catch (err) {
            console.error("Error deleting contact:", err);
            setError("Failed to delete contact. Please try again.");
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setEditedContact(prev => ({...prev, [name]: value}));
    };

    return (
        <TableRow sx={{'& > *': {padding: '8px'}}}>
            <TableCell>
                {editing ? (
                    <TextField
                        name="name"
                        value={editedContact.name}
                        onChange={handleChange}
                        fullWidth
                        variant="standard"
                    />
                ) : (
                    editedContact.name
                )}
            </TableCell>
            <TableCell>
                {editing ? (
                    <TextField
                        name="title"
                        value={editedContact.title}
                        onChange={handleChange}
                        fullWidth
                        variant="standard"
                    />
                ) : (
                    editedContact.title
                )}
            </TableCell>
            <TableCell>
                {editing ? (
                    <TextField
                        name="phone"
                        value={editedContact.phone}
                        onChange={handleChange}
                        fullWidth
                        variant="standard"
                    />
                ) : (
                    editedContact.phone
                )}
            </TableCell>
            <TableCell>
                {editing ? (
                    <TextField
                        name="email"
                        value={editedContact.email}
                        onChange={handleChange}
                        fullWidth
                        variant="standard"
                    />
                ) : (
                    editedContact.email
                )}
            </TableCell>
            <TableCell>
                {editing ? (
                    <>
                        <IconButton onClick={handleSave} size="small">
                            <SaveIcon/>
                        </IconButton>
                        <IconButton onClick={handleCancel} size="small">
                            <CancelIcon/>
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton onClick={handleEdit} size="small">
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={handleDelete} size="small">
                            <DeleteIcon/>
                        </IconButton>
                    </>
                )}
            </TableCell>
            {error && (
                <TableCell colSpan={5}>
                    <Alert severity="error">{error}</Alert>
                </TableCell>
            )}
        </TableRow>
    );
};