import React, { useEffect, useRef, useState } from 'react';
import { Menu, Button, MenuItem, Card, CardContent, Typography, IconButton, Modal, Box, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDrop } from 'react-dnd';
import axios from 'axios';
import SmallCard from './smallcard';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './ProjectCardStyle.css';
import { Delete, Title } from '@mui/icons-material';

const LargeCard = ({ largeCards, iD, title, jobid, smallCards, moveSmallCard, addSmallCardToLargeCard, updateSmallCardLargeCardId, job, fetchLargeCards, accessToken, user_id}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [staticTitle, setStaticTitle] = useState(title);
  const [editedTitle, setEditedTitle] = useState(title);
  const [smallCardTitle, setSmallCardTitle] = useState('');
  const [smallCardDescription, setSmallCardDescription] = useState('');
  const [draggedCardPosition, setDraggedCardPosition] = useState(null);
  const [smallCardsHeight, setSmallCardsHeight] = useState(0);
  const [priority, setPriority] = useState('');
  const [editing, setEditing] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deadline, setDeadline] = useState('');

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

const saveSmallCardsOrder = async (accessToken, largeCardId, smallCardsOrder) => {
  console.log("TIKI", accessToken);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/update_small_cards_order/${smallCardsOrder}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ small_cards_order: smallCardsOrder }),
    });
    if (!response.ok) {
      throw new Error("Error updating small card order");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

function handleDeleteClick() {
  console.log("accessToken", accessToken);
  axios.put(`${process.env.REACT_APP_API_URL}/del_large_card/${iD}`, {}, // Here we set an empty object for data 
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  .then((response) => {
    console.log(response);
    fetchLargeCards(accessToken, job);
  }, (error) => {
    console.log(error);
    fetchLargeCards(accessToken, job);
  });
  handleClose();
}


const [, drop] = useDrop({
  accept: 'smallCard',
  hover: (item, monitor) => {
  const smallCardsHeight = 85; 
  const draggedOffset = monitor.getSourceClientOffset();
  const hoverBoundingRect = ref.current.getBoundingClientRect();
  const hoverClientY = draggedOffset.y - hoverBoundingRect.top;

  const newIndex = Math.min(Math.max(Math.floor(hoverClientY / smallCardsHeight), 0), smallCards.length);

  setDraggedCardPosition(newIndex);
},
drop: (item, monitor) => {
//APPPII

moveSmallCard(item.id, item.largeCardId, iD, draggedCardPosition);
updateSmallCardLargeCardId(item.id, iD, draggedCardPosition);

// Find the updated large card in the largeCards state
const updatedLargeCard = largeCards.find((card) => card.large_cards.id === iD); //returns card that object is dragged too

  // Get the updated order of small cards using the updated large card
  const newSmallCardsOrder = updatedLargeCard.small_cards.map((smallCard, index) => [smallCard.id, index]);
  saveSmallCardsOrder(accessToken, iD, newSmallCardsOrder);

  setDraggedCardPosition(null);
},
});

  drop(ref);

const handleAddSmallCard = () => {
  addSmallCardToLargeCard(jobid, iD, smallCardTitle, smallCardDescription, priority, deadline, user_id);
  setSmallCardTitle('');
  setSmallCardDescription('');
  setPriority('');
  setDeadline('');
  handleClose();
};

const DropIndicator = () => {
  return (
    <div
      style={{
        height: '1px',
        backgroundColor: 'transparent',
        marginBottom: '0px',
      }}
    ></div>
  );
};

const handleSave = () => {
  setStaticTitle(editedTitle);
  handleEditSave();
  handleEditClose();
};

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const handleEditOpen = () => setOpenEdit(true);
const handleEditClose = () => setOpenEdit(false);



const handleEditClick = () => {
  handleEditClose();
  handleEditOpen();
};

function handleEditSave() {
  axios.put(`${process.env.REACT_APP_API_URL}/edit_large_card`, {
    id: iD,
    title: editedTitle,
    job_id: jobid,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then((response) => {
    console.log(response);
  }, (error) => {
    console.log(error);
  });
};

  console.log("smallCards", largeCards);

  return (
    <Card style={{ width: '25rem' ,marginLeft: '15px', marginRight: '1rem', backgroundColor: '#EDEFF2' }} className='large-card'ref={ref}>
      <CardContent>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h1 style={{fontSize: '34px', marginBottom: '10px'}}>{staticTitle}</h1>
          <IconButton onClick={handleMenuOpen} style={{}}>
            <MoreVertIcon />
          </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
          <Modal
            open={openEdit}
            onClose={handleEditClose}
            aria-labelledby="edit-small-card-modal"
            aria-describedby="edit-small-card-modal-description"
          >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="edit-small-card-modal" variant="h6" component="h2">
            Edit Tile
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label="Title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </Box>
      </Modal>
    </div>
	{smallCards.map((smallCard, index) => {
	  let adjustedIndex = index;
	  if (draggedCardPosition !== null && draggedCardPosition !== index) {
	    if (draggedCardPosition < index) {
	      adjustedIndex -= 1;
	    } else {
	      adjustedIndex += 1;
	    }
	  }   if (draggedCardPosition === index) {
	    adjustedIndex = -1; 
	  }
	  return (
	    <React.Fragment key={smallCard.id}>
	      {draggedCardPosition === index && <DropIndicator />}
      <SmallCard
        id={smallCard.id}
        title={smallCard.title}
        description={smallCard.description}
        index={adjustedIndex}
        largeCardId={iD}
        moveSmallCard={moveSmallCard}
        priority={smallCard.priority}
        deadline={smallCard.deadline}
        sCardId={smallCard.id}
        lCardId={smallCard.large_card_id}
        position={smallCard.position}
        completed={smallCard.completed}
        fetchLargeCards={fetchLargeCards}
        job={job}
        accessToken={accessToken}
      />
	      {draggedCardPosition === index + 1 && <DropIndicator />}
	    </React.Fragment>
	  );
    })}
        <IconButton onClick={handleOpen}>
          <AddIcon />
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="add-small-card-modal"
          aria-describedby="add-small-card-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="add-small-card-modal" variant="h6" component="h2">
              Add Small Tile
            </Typography>
            <TextField
	            style={{ marginTop: '5px'}}
              margin="normal"
              fullWidth
              label="Title"
              value={smallCardTitle}
              onChange={(e) => setSmallCardTitle(e.target.value)}
            />
            <TextField
              style={{ marginTop: '6px'}}
	            margin="normal"
              fullWidth
              label="Description"
              value={smallCardDescription}
              onChange={(e) => setSmallCardDescription(e.target.value)}
            />
            <TextField
            select
            style={{ marginTop: '6px'}}
            label="Priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            fullWidth
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
          </TextField>
          <TextField
            label="Deadline"
            type="datetime-local"
            style={{ marginTop: '20px'}}
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
            <Button onClick={handleAddSmallCard} variant="outlined" style={{ marginTop: '20px'}}>
              Add
            </Button>
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
};

export default LargeCard;
