import React, { useState, useEffect } from "react";
import SideNavbar from "./SideNavbar";
import Clients from "./Clients/Clients";
import Home from "./Dashboard/dashboard";
import Leads from "./Leads/leadpage";
import { useAuth0 } from "@auth0/auth0-react";

const CRM = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [home, setHome] = useState(true);
  const [clients, setClients] = useState(false);
  const [leads, setLeads] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [taskJob, setTaskJob] = useState(undefined);

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const token = await getAccessTokenSilently();
      if (!isMounted) {
        return;
      }
      setAccessToken(token);
    };
    getMessage();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  function taskJobSetter(job_id) {
    const job = get_job({ job_id, accessToken });
    console.log("job", job);
    console.log(job);
    setTaskJob(job);
    setHome(false);
    setClients(true);
  }

  if (accessToken) {
    return (
      <>
        <SideNavbar
          setLeads={setLeads}
          setHome={setHome}
          setClient={setClients}
          leads={leads}
          home={home}
          clients={clients}
        />
        <div>
          {home && <Home setTaskJob={taskJobSetter} />}
          {clients && (
            <Clients
              user={user}
              setTaskJob={setTaskJob}
              taskSetJob={taskJob}
              token={accessToken}
            />
          )}
          {leads && <Leads />}
        </div>
      </>
    );
  }
};
export default CRM;


async function get_job({ job_id, accessToken }) {
  console.log("JOBID", job_id);
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/get_smCard_project/${job_id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const data = await res.json();
  return data;
}