import axios from "axios";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AddPastJob from "../AddJobs/AddPastJob.js";
import Chip from "@mui/material/Chip";
import {IconButton} from "@mui/material";
import {Accordion, Card, Modal} from "react-bootstrap";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ProjectsDrawer from "../ProjectManager/projectdrawer.js";
import {X as Close} from "react-bootstrap-icons";
import "./PropertyDashboard.css";
import React, {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import EditableInfo from "./edit.js";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditJob from "./JobEditer.js";
import {QueryClient, useQuery} from "@tanstack/react-query";
import PropertyContacts from "./PropertyContacts/PropertyContacts";

const queryClient = new QueryClient();

function GetClientByID(iD, accessToken) {
    return useQuery({
        queryKey: ["client", iD], queryFn: async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/client_by_id/${iD}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return data;
        },
    });
}

export default function PropertyDashboard({uuid, iD, onClose, accessToken}) {
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [pastJobs, setPastJobs] = useState([]);
    const [client, setClient] = useState([]);
    const {status, data, error, isFetching, refetch} = GetClientByID(iD, accessToken);
    const [token, setToken] = useState(accessToken);
    const [symbol, setSymbol] = useState(false);
    const [property, setProperty] = useState("");
    const [showEditJobModal, setShowEditJobModal] = useState(false);

    const handleAddJobModalClose = () => {
        setShowAddJobModal(false); //&& setPastJobs(data.past_jobs);
    };
    const handleAddJobModalShow = () => {
        setShowAddJobModal(true);
        setProperty(data.property_name);
        console.log("PROPERTY", property);
    };

    const handleEditJobModalClose = () => setShowEditJobModal(false);
    const handleEditJobModalShow = () => setShowEditJobModal(true);

    const handleEditClick = () => {
        handleEditJobModalShow();
        handleEditJobModalClose();
    };
    console.log("CLIENT", client);
    console.log("ACCESS TOKEN", accessToken)
    console.log("ID", iD);
    return (<div className="property-dashboard fullscreen">
        <Card className="fullscreen-card">
            <IconButton aria-label="close " onClick={onClose}>
                <Close className="close-button" size={24}/>
            </IconButton>
            <Card.Body>
                {!data ? (<div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center", height: "30vh",
                        }}
                    >
                        <CircularProgress/>
                    </div>
                ) : (
                    <EditableInfo token={token} iD={iD}/>)}
                <PropertyContacts
                    uuid={uuid}
                    token={token}
                    propertyId={iD}
                />
                <Card.Text className="mt-4">
                    <strong>Jobs:</strong>
                </Card.Text>
                <Button
                    style={{
                        float: "left", width: "100%", marginBottom: "2%",
                    }}
                    onClick={handleAddJobModalShow}
                    variant="outlined"
                >
                    Add New Job
                </Button>
                <Modal
                    show={showAddJobModal}
                    onHide={handleAddJobModalClose}
                    size="lg"
                    contentClassName="modal-custom"
                    style={{zIndex: 2000}}
                    backdropStyle={{zIndex: 3000}}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h1
                                style={{
                                    fontWeight: "600", fontSize: "2rem", marginBottom: "0rem", paddingLeft: "1rem",
                                }}
                            >
                                New Job
                            </h1>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddPastJob
                            uuid={uuid}
                            token={token}
                            key={client.client_id}
                            clientId={iD}
                            propertyName={property}
                            onJobAdded={(job) => {
                                setSymbol(true);
                                setPastJobs([...pastJobs, job]);
                                handleAddJobModalClose();
                            }}
                        />
                    </Modal.Body>
                </Modal>
                {!data ? (<div></div>) : (<JobAccordian
                    iD={iD}
                    past_jobs={pastJobs}
                    token={token}
                    symbol={symbol}
                    setSymbol={setSymbol}
                />)}
            </Card.Body>
        </Card>
    </div>);
}

function GetJobByID({iD, token}) {
    return useQuery(["jobs", iD], async () => {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/job_by_id/${iD}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log("DATYATA", data);
        return data;
    }, {
        enabled: !!iD,
    });
}

function JobAccordian({iD, past_jobs, token, symbol, setSymbol}) {
    const {status, data, error, isFetching, refetch} = GetJobByID({
        iD, token,
    });
    const [accordionKey, setAccordionKey] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [jobEdit, setJobEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [jobs, setJobs] = useState([past_jobs]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleEditJob = () => {
        setJobEdit(!jobEdit);
    };

    if (symbol) {
        refetch();
        setSymbol(false);
    }

    const handleClose = () => {
        setJobEdit(false);
    };

    const handleClick = (event, job) => {
        setAnchorEl(event.currentTarget);
        setSelectedJob(job);
    };

    const handleDelete = (token) => {
        handleDeleteClick(token, selectedJob.job.id);
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        setAnchorEl(null);
        setJobEdit(true);
    };

    const handleDeleteClick = (token, id) => {
        let response = axios
            .delete(`${process.env.REACT_APP_API_URL}/del_job/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                refetch();
            }, (error) => {
                console.log(error);
            });
    };

    const getStatusClassName = (status) => {
        return `status-${status.toLowerCase().replace(" ", "-")}`;
    };

    if (!data) {
        return null;
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (<Accordion defaultActiveKey="0" activeKey={accordionKey}>
        {data.map((job, index) => (<Card key={index}>
            <Card.Header style={{backgroundColor: "transparent"}}>
                <Button
                    style={{width: "100%", color: "transparent"}}
                    variant="link"
                    onClick={() => {
                        setAccordionKey(accordionKey === job.job.id.toString() ? null : job.job.id.toString());
                        setSelectedJob(job);
                    }}
                >
                    <Box sx={{flexGrow: 1}}>
                        <Grid Item xs={1}>
                            <p
                                style={{
                                    color: "#1C2127", textOverflow: "ellipsis", whiteSpace: "nowrap",
                                }}
                            >
                                <strong>{job.job.title} </strong>
                            </p>
                        </Grid>
                        <Grid container spacing={0}>

                            <Grid item xs={2.0}>
                                <p
                                    style={{
                                        color: "#1C2127", fontSize: ".8rem",
                                    }}
                                >
                                    {" "}
                                    {job.job.start_date}
                                </p>
                            </Grid>
                            <Grid style={{allign: "left"}} item xs={3}>
                                <div style={{allign: "left", margin: "auto 0"}}>
                                    <JobChip job_name={job.job.job_type}/>
                                </div>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                    <span
                        style={{justifyContent: "right"}}
                        className={getStatusClassName(job.job.status)}
                    >
                      {job.job.status}
                    </span>
                            </Grid>
                        </Grid>
                    </Box>
                </Button>
            </Card.Header>
            <Accordion.Collapse eventKey={job.job.id.toString()}>
                <Card.Body>
                    <Grid container>
                        <Grid item xs={5}>
                            <JobTypeNameHead jobhead={job.job.job_type}/>
                            <p>
                                <strong>Title:</strong> {job.job.title}
                            </p>
                            <p>
                                <strong>Description:</strong> {job.job.description}
                            </p>
                            <p>
                                <strong>Net Zero Equipment:</strong>{" "}
                                {job.job.net_zero_equipment}
                            </p>
                            <p>
                                <strong>Revenue:</strong> {job.job.revenue}
                            </p>
                        </Grid>
                        <Grid
                            item
                            sx={6}
                            style={{paddingTop: "3rem", paddingLeft: "3rem"}}
                        >
                            <JobByType
                                job={job.jobtype}
                                job_type_name={job.job.job_type}
                            />
                            <p>
                                <strong>Trello Link:</strong>
                                <a href={job.job.trello_link}>{job.job.trello_link}</a>
                            </p>
                            <Button
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#2D72D2",
                                    marginTop: ".9rem",
                                    borderRadius: 4,
                                    fontSize: "0.8rem",
                                    padding: "4px 8px",
                                    minWidth: "unset",
                                }}
                                endIcon={<KeyboardDoubleArrowRightIcon/>}
                                onClick={() => toggleDrawer(job)}
                            >
                                Project View
                            </Button>
                            <Modal size="lg" show={jobEdit} onHide={() => handleClose()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h1
                                            style={{
                                                fontWeight: "600",
                                                fontSize: "2rem",
                                                marginBottom: "0rem",
                                                paddingLeft: "1rem",
                                            }}
                                        >
                                            Edit Job
                                        </h1>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <EditJob
                                        job={selectedJob}
                                        onJobEdited={(selectedJob) => {
                                            setSelectedJob(selectedJob);
                                            refetch();
                                            handleClose();
                                        }}
                                        accessToken={token}
                                    />
                                </Modal.Body>
                            </Modal>
                        </Grid>
                        <Grid className="more-vert">
                            <IconButton
                                className="more-vert"
                                style={{marginRight: "1.2rem"}}
                                onClick={() => handleEditClick(job)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Grid>
                        <Grid className="more-vert">
                            <IconButton
                                onClick={() => handleDelete(token)}
                                style={{marginTop: "2rem"}}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <ProjectsDrawer
                        open={drawerOpen}
                        job={selectedJob}
                        onClose={() => toggleDrawer(null)}
                        accessToken={token}
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>))}
    </Accordion>);
}

const JobChip = ({job_name}) => {
    switch (job_name) {
        case "NetZeroLighting":
            return (<>
                <Chip
                    label="Net Zero Lighting"
                    variant="outlined"
                    sx={{color: "#2F343C", marginLeft: "1rem"}}
                />
            </>);
        case "NetZeroPTACcleaning":
            return (<>
                <Chip
                    label="Net Zero HVAC Cleaning"
                    variant="outlined"
                    sx={{color: "#2F343C", marginLeft: "1rem"}}
                />
            </>);
        case "NetZeroThermostats":
            return (<>
                <Chip
                    label="Net Zero Thermostats"
                    variant="outlined"
                    sx={{color: "#2F343C", marginLeft: "1rem"}}
                />
            </>);
        case "NewBuildIncentives":
            return (<>
                <Chip
                    label="Net Zero Build Incentives"
                    variant="outlined"
                    sx={{color: "#2F343C", marginLeft: "1rem"}}
                />
            </>);
        case "ThermostatSale":
            return (<>
                <Chip
                    label="Net Zero Thermostat Sale"
                    variant="outlined"
                    sx={{color: "#2F343C", marginLeft: "1rem"}}
                />
            </>);
    }
};
const JobTypeNameHead = ({jobhead}) => {
    switch (jobhead) {
        case "NetZeroLighting":
            return <h3>Net Zero Lighting</h3>;
        case "NetZeroPTACcleaning":
            return <h3>Net Zero HVAC Cleaning</h3>;
        case "NetZeroThermostats":
            return <h3>Net Zero Thermostats</h3>;
        case "ThermostatSale":
            return <h3>Net Zero Thermostat Sale</h3>;
        case "NewBuildIncentives":
            return <h3>New Build Incentives</h3>;
    }
};
const JobByType = ({job, job_type_name}) => {
    switch (job_type_name) {
        case "NetZeroLighting":
            return (<>
                <p>
                    <strong>Number of free bulbs:</strong>{" "}
                    {job.NetZeroLighting.n_free_bulbs}
                </p>
                <p>
                    <strong>Dollars saved:</strong>{" "}
                    {job.NetZeroLighting.free_bulb_dollars}
                </p>
                <p>
                    <strong>KW reduced:</strong> {job.NetZeroLighting.kw_reduced}
                </p>
            </>);
        case "NetZeroPTACcleaning":
            return (<>
                <p>
                    <strong>Number of units cleaned:</strong>{" "}
                    {job.NetZeroPTACcleaning.n_units_cleaned}
                </p>
                <p>
                    <strong>dollars saved:</strong>{" "}
                    {job.NetZeroPTACcleaning.free_cleaning_dollars}
                </p>
                <p>
                    <strong>KW reduced:</strong> {job.NetZeroPTACcleaning.kw_reduced}
                </p>
            </>);
        case "NetZeroThermostats":
            return (<>
                <p>
                    <strong>Number of thermostats:</strong>{" "}
                    {job.NetZeroThermostats.n_free_therm}
                </p>
                <p>
                    <strong>dollars saved:</strong>{" "}
                    {job.NetZeroThermostats.free_therm_dollars}
                </p>
                <p>
                    <strong>KW reduced:</strong> {job.NetZeroThermostats.kw_reduced}
                </p>
            </>);
        case "NewBuildIncentives":
            return (<>
                <p>
                    <strong>Rebates Provided:</strong>{" "}
                    {job.NewBuildIncentives.rebate_provided}
                </p>
            </>);
        case "ThermostatSale":
            return (<>
                <p>
                    <strong> Number of thermostats:</strong>{" "}
                    {job.ThermostatSale.n_therm}
                </p>
                <p>
                    <strong>dollars saved:</strong> {job.ThermostatSale.rebate_provided}
                </p>
                <p>
                    <strong>KW reduced:</strong>
                    {job.ThermostatSale.kw_reduced}
                </p>
            </>);
    }
};
